import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';
import { convertNumToWord, getPaymentNumbers, parseDecimal, parseTime } from 'utils/helpers';

import { Props } from './appointmentCard.types';

const AppointmentCard: React.FC<Props> = ({
  copayInfo,
  category,
  providerName = 'doctor',
  time,
  pricePoint,
  discount,
  creditBalance,
  freeAppointmentInfo,
  isLifeMDPlusFlow,
  callMethod,
  membershipInfo,
  isAsync
}) => {
  const appointmentCost = isAsync
    ? pricePoint.asyncAppointmentCost
    : pricePoint.subsequentAppointmentCost;
  const { discountValue, creditUsed, finalPrice } = getPaymentNumbers(
    membershipInfo?.paymentDue
      ? membershipInfo.paymentDue
      : copayInfo?.copayAmount || appointmentCost,
    freeAppointmentInfo?.isFree ? '100%' : discount,
    creditBalance,
    isLifeMDPlusFlow
  );
  const discountForPlan = Number(membershipInfo?.totalCost) - Number(discount || 0);

  const isMessageBasedAppointment = callMethod === 'message';

  const rowClassName = 'mb-1 flex justify-between font-bold md:mb-0.5';
  const rowWrapperClassName = 'mb-4 md:mb-3';
  const subheadingClassName = 'block text-mSm md:text-sm';

  return (
    <div className="rounded-2xl border border-secondary-200 bg-secondary-50 px-4 py-6 md:p-6">
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName}>
          <span className="block">{!!copayInfo ? 'Copay' : 'This appointment'}</span>
          <span
            className=""
            data-testid="provider_appointment_price"
          >{`$${copayInfo?.copayAmount || appointmentCost}`}</span>
        </div>
        {isLifeMDPlusFlow && (
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>
            {isMessageBasedAppointment
              ? 'Message-based appointment'
              : 'Video-based visit with a provider'}
          </span>
        )}
        {time && category && !isLifeMDPlusFlow && (
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>
            {`Your ${category} visit. With ${providerName} ${parseTime(time)}`}
          </span>
        )}
      </div>
      {membershipInfo && (
        <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
          <div className={rowClassName}>
            <span className="block">{membershipInfo.planName}</span>
            {!!discount ? (
              <span>
                <span className="line-through opacity-30">{`$${membershipInfo.totalCost}`}</span>
                {` ${discountForPlan === 0 ? 'Free' : `$${discountForPlan}`}`}
              </span>
            ) : (
              <span
                className=""
                data-testid="provider_appointment_price"
              >{`$${membershipInfo.totalCost}`}</span>
            )}
          </div>
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>
            Renews at {`$${membershipInfo.totalCost}`}
            {`${membershipInfo?.paymentIntervalUnits}`}
          </span>
        </div>
      )}
      {freeAppointmentInfo?.isFree && (
        <div className={classNames(rowWrapperClassName, 'text-orange')}>
          <div className={rowClassName}>
            <span className="block">Appointment credit</span>
            <span>{`-$${appointmentCost}`}</span>
          </div>
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>{`${dayjs(
            freeAppointmentInfo.periodStart
          ).format(DateFormat.MMM_DD)} - ${dayjs(freeAppointmentInfo.periodEnd).format(
            DateFormat.MMM_DD
          )}`}</span>
        </div>
      )}
      {!!discount && !isLifeMDPlusFlow && (
        <div className={classNames(rowWrapperClassName, 'text-orange')}>
          <div className={rowClassName}>
            <span className="block">{`Discount: ${discount} off`}</span>
            <span>-${discountValue}</span>
          </div>
        </div>
      )}
      {!!creditBalance && (
        <div className={classNames(rowWrapperClassName, 'text-primary')}>
          {+creditUsed > 0 && (
            <div className={rowClassName}>
              <span className="block">LifeMD Credit applied</span>
              <span data-testid="credit_used">{`$${parseDecimal(creditUsed)}`}</span>
            </div>
          )}
          {+creditBalance > 0 && (
            <span className={subheadingClassName}>{`Available credit: $${creditBalance}`}</span>
          )}
        </div>
      )}
      <div className="my-4 h-px w-full bg-secondary" />
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName} data-testid="total_due">
          <span>Total due now</span>
          <span>{`$${finalPrice}`}</span>
        </div>
      </div>
      {!copayInfo && (
        <p className="text-mSm md:text-sm">
          {freeAppointmentInfo?.isFree
            ? `You have ${
                convertNumToWord(Number(freeAppointmentInfo.freeAppointmentsAmount)) || ''
              } free appointment available to be scheduled between ${dayjs(
                freeAppointmentInfo.periodStart
              ).format(DateFormat.MMM_DD)} and ${dayjs(freeAppointmentInfo.periodEnd).format(
                DateFormat.MMM_DD
              )}`
            : `Your plan includes access to single-issue visits for $${appointmentCost}.`}
        </p>
      )}
    </div>
  );
};

export default AppointmentCard;
