import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import AskToUpgradeToTheWM from 'modals/AskToUpgradeToTheWM';
import RadioGroup from 'shared/RadioGroup';

import useWeightManagement from 'hooks/useWeightManagement';

import {
  PrescriptionsTypeOption,
  PrescriptionsTypeProps,
  PrescriptionsValueType
} from './prescriptionsType.types';

const ITEMS: PrescriptionsTypeOption[] = [
  {
    label: 'Weight management',
    value: 'weight-management'
  },
  {
    label: 'Something else...',
    value: 'something-else'
  }
];

const PrescriptionsType: React.FC<PrescriptionsTypeProps> = ({ onChoosePrescriptionsType }) => {
  const { isWeightManagement } = useWeightManagement();
  const [isUpgradePopupOpen, toggleUpgradePopup] = useToggle(false);

  const onClickOption = (prescriptionsValueType: PrescriptionsValueType) => {
    if (prescriptionsValueType === 'weight-management' && !isWeightManagement) {
      return toggleUpgradePopup();
    }

    onChoosePrescriptionsType({
      prescriptionsValueType
    });
  };

  return (
    <>
      <AskToUpgradeToTheWM
        alertContent={
          <>
            <b>Please upgrade your plan</b> to book a weight management appointment.
          </>
        }
        declineButtonText="Close"
        isOpen={isUpgradePopupOpen}
        onClose={toggleUpgradePopup}
        onReopen={() => toggleUpgradePopup(true)}
      />
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Common.ColorTag color="primary" text="Prescriptions" />
          <h1 className="text-m2xl font-bold text-primary-700">
            What type of prescription do you need?
          </h1>
        </div>
        <Common.Alert type="info" colorableBackground>
          <b>Note:</b> We can only renew medications prescribed by a LifeMD provider.
        </Common.Alert>
        <RadioGroup items={ITEMS} onSelect={onClickOption} />
      </div>
    </>
  );
};

export default PrescriptionsType;
