import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { useMessages } from 'hooks';
import { MessageTab, PathName } from 'utils/enums';

const AllSet = () => {
  const navigate = useNavigate();
  const { generalHealth } = useMessages();

  const handleMessageCareTeam = () => {
    const navigationParams = {
      pathname: PathName.Messages,
      search: `tab=${MessageTab.CARE_TEAM}&chatId=${generalHealth}`
    };
    navigate(navigationParams);
  };

  return (
    <div className="flex flex-col items-center gap-4 max-md:h-full md:gap-8">
      <div className="flex flex-col items-center gap-2 text-center max-md:py-6">
        <Common.Illustration name="success" />
        <h2 className="wm-signup-title">You’re all set!</h2>
        <p>
          <span className="font-bold text-gray-700">Thanks for completing your survey.</span> You’re
          all set to continue your weight loss journey.
        </p>
      </div>
      <div className="mt-auto flex w-full gap-3 max-md:flex-col max-md:py-6 md:justify-center">
        <Common.Button color="blue" fullWidthOnMobile onClick={() => navigate(PathName.Dashboard)}>
          Done
        </Common.Button>
        <Common.Button color="white-alt" fullWidthOnMobile onClick={handleMessageCareTeam}>
          Message care team
        </Common.Button>
      </div>
    </div>
  );
};

export default AllSet;
