import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetSet } from 'react-use';

import { useVerifyIdentityViaCrossCheckMutation } from 'services/general/general';
import { useAddInsuranceMutation, useLazyGetInsurancesQuery } from 'services/insurance/insurance';
import { GetInsurancesResProps } from 'services/insurance/insurance.types';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';
import { GetMyAccountResProps } from 'services/myAccount/myAccount.types';

import { selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import { Props } from 'containers/PostOnboarding/Content/content.types';
import { defineFlowSteps } from 'containers/PostOnboarding/postOnboarding.settings';
import { POST_ONBOARDING_STEPS, StepName } from 'containers/PostOnboarding/postOnboarding.types';
import { notifyError, notifySuccess } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';

import useKrogerIDConnected from './useKrogerIDConnected';
import usePartnerPatient from './usePartnerPatient';
import useWeightManagement from './useWeightManagement';

export const usePostOnboarding = () => {
  const isKrogerPatient = usePartnerPatient('Kroger');
  const isBlueLinePatient = usePartnerPatient('Blue Line');
  const isBalladHealthPatient = usePartnerPatient('Ballad Health');

  const [getMyAccount] = useLazyGetMyAccountQuery();
  const [getInsurance] = useLazyGetInsurancesQuery();
  const [verifyIdentityViaCrossCheck] = useVerifyIdentityViaCrossCheckMutation();
  const [addInsurance] = useAddInsuranceMutation();
  const { isAsyncPlan } = useWeightManagement();
  const { isKrogerIDConnected } = useKrogerIDConnected();

  const dispatch = useAppDispatch();
  const { identityVerified: isVerified, state } = useAppSelector(selectUser);

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [steps, setSteps] = useState([...POST_ONBOARDING_STEPS]);
  const query = useQuery();
  const navigate = useNavigate();
  const currentStep = (query.get('s') ?? '') as StepName;

  const fetchData = async (): Promise<
    | {
        error: string;
        insuranceData: null;
        myAccountData: null;
      }
    | {
        error: null;
        insuranceData: GetInsurancesResProps;
        myAccountData: GetMyAccountResProps;
      }
  > => {
    let myAccountData = null;
    let insuranceData = null;
    let error = null;

    try {
      setIsFetching(true);
      myAccountData = await getMyAccount().unwrap();
      insuranceData = await getInsurance().unwrap();
      return { error: null, insuranceData, myAccountData };
    } catch (e) {
      error = (e as Error).message ?? 'Error fetching data';
      return { error, insuranceData: null, myAccountData: null };
    } finally {
      setIsFetching(false);
    }
  };

  const exitFlow = async () => {
    try {
      await fetchData();
    } finally {
      navigate(PathName.Dashboard);
    }
  };

  const makeCrossCheck = async () => {
    try {
      setIsFetching(true);
      const data = await verifyIdentityViaCrossCheck().unwrap();
      if (data.data.isVerified) {
        dispatch(setUser({ identityVerified: true }));
      }
      return data.data.isVerified;
    } catch (_e) {
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  const moveToStep: Props['moveToStep'] = (type, extraSearch = ''): void | Promise<void> => {
    if (typeof type === 'object') {
      const { answer, step } = type;
      switch (step) {
        case 'pre-insurance':
          if (answer) {
            return moveToStep('next');
          } else {
            const nextStepAfterInsurance = steps[steps.indexOf('insurance') + 1];
            return moveToStep(nextStepAfterInsurance);
          }
        case 'verify-identity':
          if (answer) {
            return moveToStep('next');
          } else {
            const nextStepAfterIdentity = steps[steps.indexOf('verify-identity') + 1];
            if (!nextStepAfterIdentity) {
              exitFlow();
              return;
            }

            return moveToStep(nextStepAfterIdentity);
          }
        default:
          break;
      }
    } else {
      const additionalSearch = extraSearch ? '&' + extraSearch : '';
      if (type === 'prev') {
        toggleBackAnimation(true);
        return navigate(-1);
      } else if (type === 'next') {
        toggleBackAnimation(false);
        const currentStepIndex = steps.indexOf(currentStep);
        let nextStep = steps[currentStepIndex + 1];
        // here is additional logic to skip verify identity steps if user is already verified by crosscheck
        if (currentStep === 'address' && isVerified && steps.includes('verify-identity')) {
          nextStep = steps[steps.indexOf('verify-identity') + 1];
        }
        if (currentStepIndex + 1 >= steps.length || !steps.includes(nextStep)) {
          exitFlow();
          return;
        }
        return navigate({ search: `s=${nextStep}${additionalSearch}` });
      } else {
        if (!steps.includes(type)) {
          toggleBackAnimation(true);
          notifyError('Something went wrong, please try again');
          return navigate({ search: `s=${steps[0]}` }, { replace: true });
        }
        toggleBackAnimation(false);
        navigate({ search: `s=${type}${additionalSearch}` });
      }
    }
  };

  const handleSelectInsurance = async (value: boolean) => {
    if (value) {
      moveToStep({ answer: value, step: 'pre-insurance' });
    } else {
      setIsFetching(true);
      await addInsurance({
        hasInsurance: value
      })
        .unwrap()
        .finally(() => {
          setIsFetching(false);
          moveToStep({ answer: value, step: 'pre-insurance' });
        });
    }
  };

  const onInit = () => {
    const getData = async () => {
      try {
        setLoading(true);
        const { myAccountData, insuranceData, error } = await fetchData();
        if (error !== null) {
          return notifyError(error);
        }
        const {
          data: {
            medicalIntakeCompleted,
            address,
            activePlanCode,
            identityVerified,
            sexAtBirth,
            isFirstAppointmentCompleted,
            dob,
            bodyImage
          }
        } = myAccountData;
        const {
          data: { insurances, hasInsurance }
        } = insuranceData;
        const filteredSteps = defineFlowSteps(POST_ONBOARDING_STEPS, {
          activePlanCode,
          address,
          dob,
          hasInsurance,
          identityVerified,
          insurances,
          isAsyncPlan,
          isFirstAppointmentCompleted,
          medicalIntakeCompleted: !medicalIntakeCompleted,
          sexAtBirth,
          state,
          bodyImage: !!bodyImage?.uploadRequired,
          isKrogerPatient,
          isKrogerIDConnected,
          hideInsuranceByPartner: isBlueLinePatient || isBalladHealthPatient
        });
        if (filteredSteps.length === 0) {
          notifySuccess('You have successfully completed all the necessary steps.', false, {
            toastId: 'success'
          });
          return navigate(PathName.Dashboard);
        } else {
          setSteps(filteredSteps);
          if (!currentStep || !filteredSteps.includes(currentStep)) {
            navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
          }
        }
      } catch (e) {
        notifyError((e as Error).message ?? 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };
    getData();
  };

  useEffect(onInit, []);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, [currentStep]);

  return {
    currentStep,
    fetchData,
    handleSelectInsurance,
    isFetching,
    loading,
    makeCrossCheck,
    moveToStep,
    steps,
    isBackAnimation: isBackAnimation()
  };
};
