import { AppointmentProps } from 'store/appointments/appointments.types';

import { PossibleStepsType, StepName } from './createAppointmentExtended.types';

export const buildBodyForApptSchedule = (
  data: Omit<AppointmentProps, '_id' | 'files' | 'category'>
) => ({
  appointmentDescription: data.appointmentDescription.trim(),
  appointmentMethod: data.callType,
  appointmentTime: {
    endTime: data.endTime,
    startTime: data.startTime
  },
  appointmentTypeId: data.appointmentTypeId,
  doctorId: data.doctorId,
  isWeightManagementAppointment: data.isWeightManagementAppointment
});

export const defineFlowSteps = (
  steps: PossibleStepsType,
  params: {
    haveToPayForAppt: boolean;
    isExistingPatient: boolean;
    isInsurancePatient: boolean;
    isRequiredToUpgradeToLifeMDPlus: boolean;
  }
): StepName[] => {
  let copySteps: StepName[] = [...steps];
  const stepsToFilter: StepName[] = [];
  // Check different conditions to filter steps by adding steps to filter array in the end

  // 1. If the user is an existing patient, we remove create profile steps
  if (params.isExistingPatient) {
    stepsToFilter.push(
      'create-account-intro',
      'create-account-personal-details',
      'create-account-address',
      'create-account-dob',
      'create-account-phone',
      'create-account-password'
    );
  }

  if (params.isInsurancePatient) {
    stepsToFilter.push('qualified-for-async');
  }

  // 2. If the user has an unlimited plan, we remove the membership and checkout steps
  // if (!params.isRequiredToUpgradeToLifeMDPlus) {
  //   stepsToFilter.push('subscription-required');
  // }

  // 3. If the user has freer appt in their plan, we remove the checkout step
  if (!params.haveToPayForAppt) {
    stepsToFilter.push('payment-checkout');
  }

  copySteps = copySteps.filter((step) => !stepsToFilter.includes(step));
  return copySteps;
};
