import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectMifInfo } from 'store';

import CheckboxGroup from 'shared/CheckboxGroup';

import { useAppSelector } from 'hooks';

import { MifQuestionTwoProps } from './mifQuestionTwo.types';

const MifQuestionTwo: React.FC<MifQuestionTwoProps> = ({ onContinue, config, loading }) => {
  const { tirzepatideMif } = useAppSelector(selectMifInfo);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelect = (v: number | string) => {
    let newArray: string[] = [];
    const stringValue = String(v);
    if (v === 'none') {
      if (!selectedItems.includes('none')) {
        newArray = [v];
      }
    } else {
      newArray = selectedItems.includes(stringValue)
        ? selectedItems.filter((i) => i !== stringValue && i !== 'none')
        : [...selectedItems, stringValue].filter((i) => i !== 'none');
    }

    setSelectedItems(newArray);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onContinue({ answer: selectedItems, question: config.question.value });
  };

  useEffect(() => {
    const selected =
      tirzepatideMif.find((item) => item.question === config.question.value)?.answer ?? [];
    if (selected.length) {
      setSelectedItems(selected);
    }
  }, []);

  return (
    <form className="flex min-h-full flex-col gap-6" onSubmit={handleSubmit}>
      <h2 className="text-m2xl font-bold text-primary-700 md:text-2xl">
        Have you had difficulty with any of the following?
      </h2>
      <Common.Alert type="info" colorableBackground>
        Your responses will allow us to provide personalized recommendations which will help us
        determine the appropriate medication for you.
      </Common.Alert>
      <CheckboxGroup items={config.options} selectedItems={selectedItems} onSelect={handleSelect} />
      <Common.Button
        className="py-6 md:self-center"
        color="blue"
        disabled={!selectedItems.length}
        isLoading={loading}
        fullWidthOnMobile
      >
        Continue
      </Common.Button>
    </form>
  );
};

export default MifQuestionTwo;
