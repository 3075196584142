import { useEffect } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { useGetSet } from 'react-use';
import uniqBy from 'lodash/uniqBy';

import { useSendMigrationDecisionMutation } from 'services/general/general';
import { UserDecision } from 'services/general/general.types';
import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectLookup, selectMifInfo, selectUser } from 'store';
import { MifStateProps } from 'store/mif/mif.types';
import { clearTirzepatideMif, setTirzepatideMif } from 'store/mif/mifSlice';

import {
  PossibleMoveToStepTypes,
  Props
} from 'containers/MigrateFromTirzepatide/Content/content.types';
import {
  FillMifDataProps,
  MIGRATE_FROM_TIRZEPATIDE_STEPS,
  PatchedQuestion,
  StepName
} from 'containers/MigrateFromTirzepatide/migrateFormTirzepatide.types';
import { notifyError } from 'shared/Toast/Toast';

import { SEMAGLUTIDE_PRICE_POINT, TIRZEPATIDE_PRICE_POINT } from 'constants/pricepoints';
import { PathName, PlanCodes } from 'utils/enums';
import { findMaintenancePP, handleRequestCatch } from 'utils/helpers';

import { UserCurrentWMPPType } from 'models/user.types';

import { useAppDispatch, useAppSelector, useQuery } from './index';
const ID_FOR_MIF_REQUESTS = 'tirzepatide-migration';

const buildPayload = (type: PossibleMoveToStepTypes): MifStateProps['tirzepatideMif'] | null => {
  let payload: MifStateProps['tirzepatideMif'] | null = null;
  if (typeof type === 'object') {
    switch (type.step) {
      case 'mif-1':
      case 'mif-2':
        payload = [type.data];
        break;
      // add more conditions here
      default:
        break;
    }
  }
  return payload;
};

const getNextStep = (
  type: FillMifDataProps,
  {
    mifCheckResults = ''
  }: {
    mifCheckResults?: string;
  }
): StepName | null => {
  let nextStep: StepName | null = null;
  switch (type.step) {
    case 'mif-1':
      nextStep = 'mif-2';
      break;
    case 'mif-2':
      nextStep = 'results';
      break;
    case 'results':
      if (type.data) {
        if (['not-eligible-bundled', 'not-eligible-maintenance'].includes(mifCheckResults)) {
          nextStep = 'select-medication';
        }
      } else {
        nextStep = 'appointment';
      }
      break;
    case 'select-medication':
      nextStep = 'checkout';
      break;
    default:
  }
  return nextStep;
};

export const useMigrateFromTirzepatide = (onComplete: () => void, onLeaveFlow: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentStep = (useQuery().get('s') ?? '') as StepName;

  const { tirzepatideMif } = useAppSelector(selectMifInfo);
  const { membershipPlans } = useAppSelector(selectLookup);
  const { activePricePoint, tirzepatideMigration } = useAppSelector(selectUser);

  const mifCheckResults = tirzepatideMigration?.eligibilityType;

  const { isFetching } = useGetMembershipPlansQuery();
  const { data, isLoading: isMifLoading } = useGetMifStructureQuery(ID_FOR_MIF_REQUESTS);
  const [submitMif, { isLoading: isSubmittingMif }] = useSendMifResponseMutation();
  const [getMyAccount, { isFetching: isGettingMyAccount }] = useLazyGetMyAccountQuery();
  const [updateMyAccount, { isLoading: isUpdatingUser }] = useUpdateMyAccountMutation();
  const [sendDecision, { isLoading: isSendingMigrationDecision }] =
    useSendMigrationDecisionMutation();

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);

  const mifSteps = (data?.data.configs ?? [])
    .filter((c) => c.config.type !== 'info')
    .toSorted((a, b) => a.order - b.order) as PatchedQuestion[];
  const WMPLan = membershipPlans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
  const pricePoints = WMPLan?.pricePoints;

  const userCurrentPPType: UserCurrentWMPPType =
    activePricePoint?.includes('low-dose') || activePricePoint?.includes('maintenance')
      ? 'maintenance'
      : activePricePoint?.includes('bundle')
        ? 'bundle'
        : 'care-only';

  const userCurrentPP = pricePoints?.find((pp) => pp.planPricePointId === activePricePoint);

  const tirzepatideMaintenancePP = findMaintenancePP(pricePoints, {
    keyword: 'tirzepatide'
  });
  const semaglutideMaintenancePP = findMaintenancePP(pricePoints, {
    keyword: 'semaglutide'
  });

  const ppToUpgrade = pricePoints?.find(
    (pp) =>
      pp.planPricePointId ===
      (userCurrentPPType === 'bundle'
        ? SEMAGLUTIDE_PRICE_POINT
        : semaglutideMaintenancePP?.planPricePointId)
  );

  const tirzepatidePP = pricePoints?.find((pp) =>
    userCurrentPPType === 'bundle'
      ? pp.planPricePointId === TIRZEPATIDE_PRICE_POINT
      : tirzepatideMaintenancePP
  );

  const handleCompleteFlow = (manualAction?: UserDecision | 'skip') => {
    const getAction = (): UserDecision | null => {
      if (manualAction === 'skip') {
        return null;
      }
      if (manualAction) {
        return manualAction;
      }
      switch (mifCheckResults) {
        case 'eligible':
          return null;
        case 'not-eligible-care-only':
          return 'accepted-semaglutide';
        case 'not-eligible-bundled':
          return 'accepted-tirzepatide-l-arginine';
        case 'not-eligible-maintenance':
          return 'accepted-tirzepatide-l-arginine-maintenance';
        default:
          return null;
      }
    };
    const action = getAction();
    if (!action) {
      dispatch(clearTirzepatideMif());
      return onComplete();
    }
    sendDecision({
      action
    })
      .unwrap()
      .then(() => {
        dispatch(clearTirzepatideMif());
        onComplete();
      })
      .catch(handleRequestCatch);
  };

  const exitFlow = (to: To, options?: NavigateOptions) => {
    navigate(to, options);
    dispatch(clearTirzepatideMif());
  };

  const handleUpdateMyAccount = () => {
    updateMyAccount({
      planPricePointId: ppToUpgrade?.planPricePointId,
      planId: WMPLan?._id ?? ''
    })
      .unwrap()
      .then(() => getMyAccount().unwrap())
      .then(() => handleCompleteFlow('skip'))
      .catch((error) =>
        handleRequestCatch(error, 'Error during plan upgrade, please try again later')
      );
  };

  const moveToStep: Props['moveToStep'] = async (type, extraSearch = ''): Promise<void> => {
    const additionalSearch = extraSearch ? '&' + extraSearch : '';
    const currentStepIndex = MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(currentStep);
    // if we need to store some data in redux, let's do it here
    const payload = buildPayload(type);
    payload && dispatch(setTirzepatideMif(payload));

    // when arg type is an object, then we'll have conditional next step, so below is the logic to define it
    if (typeof type === 'object') {
      const nextStep = getNextStep(type, { mifCheckResults });

      if (type.step === 'mif-2') {
        const body = uniqBy([...tirzepatideMif, type.data], 'question');
        await submitMif({ body, id: ID_FOR_MIF_REQUESTS })
          .unwrap()
          .then(() => getMyAccount().unwrap())
          .catch(handleRequestCatch);
      }

      if (type.step === 'results' && !nextStep) return handleCompleteFlow();
      if (type.step === 'appointment') return handleCompleteFlow('requested-appointment');
      if (type.step === 'checkout') return handleUpdateMyAccount();

      const indexOfCurrentStep = MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(type.step);
      const indexOfTheNextStep = !!nextStep ? MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(nextStep) : 0;
      toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
      return nextStep
        ? navigate({ search: `s=${nextStep}${additionalSearch}` })
        : exitFlow({ pathname: PathName.Dashboard });
    } else if (type === 'prev') {
      if (MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(currentStep) === 0) {
        return onLeaveFlow();
      }
      toggleBackAnimation(true);
      return navigate(-1);
    } else if (type === 'next') {
      toggleBackAnimation(false);
      let nextStep = MIGRATE_FROM_TIRZEPATIDE_STEPS[currentStepIndex + 1];
      return nextStep
        ? navigate({ search: `s=${nextStep}${additionalSearch}` })
        : exitFlow({ pathname: PathName.Dashboard });
    } else {
      if (!MIGRATE_FROM_TIRZEPATIDE_STEPS.includes(type)) {
        toggleBackAnimation(true);
        notifyError('Something went wrong, please try again');
        return navigate({ search: `s=${MIGRATE_FROM_TIRZEPATIDE_STEPS[0]}` }, { replace: true });
      }
      toggleBackAnimation(false);
    }
  };

  const onInit = () => {
    dispatch(clearTirzepatideMif());
    if (
      !!tirzepatideMigration?.patientActionSubmittedAt ||
      !!tirzepatideMigration?.patientAction
      // ||
      // (mifCheckResults === 'eligible' && !!tirzepatideMigration?.mifSubmittedAt)
    ) {
      navigate({ search: `s=all-set` }, { replace: true });
    } else if (!currentStep || !MIGRATE_FROM_TIRZEPATIDE_STEPS.includes(currentStep)) {
      navigate({ search: `s=intro` }, { replace: true });
    }
  };

  useEffect(onInit, []);

  return {
    loading: isSendingMigrationDecision || isUpdatingUser || isFetching || isMifLoading,
    currentStep,
    userCurrentPPType,
    userCurrentPP,
    tirzepatidePP,
    ppToUpgrade,
    isBackAnimation: isBackAnimation(),
    moveToStep,
    mifSteps,
    isSubmittingMif,
    isGettingMyAccount
  };
};
