import { FormProps } from 'widgets/mif/SocialHistory/socialHistory.types';

import { Gender, SexAtBirth } from 'utils/enums';

import { Option } from 'models/forms.types';

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const GENDER_OPTIONS: Option<Gender>[] = [
  { label: 'Man', value: Gender.Man },
  { label: 'Woman', value: Gender.Woman },
  { label: 'Transgender man / trans masculine', value: Gender.TransgenderMan },
  { label: 'Transgender woman / trans feminine', value: Gender.TransgenderWoman },
  { label: 'Non-binary / genderqueer / gender fluid', value: Gender.NonBinary },
  { label: 'Two spirit', value: Gender.TwoSpirit },
  { label: 'Option not listed', value: Gender.NotListed },
  { label: 'Prefer not to say', value: Gender.PreferToHide },
  { label: 'Unknown', value: Gender.Unknown }
];

export const SEX_AT_BIRTH_OPTIONS: Option<SexAtBirth>[] = [
  { label: 'Male', value: SexAtBirth.Male },
  { label: 'Female', value: SexAtBirth.Female },
  { label: 'Intersex/Other', value: SexAtBirth.Other },
  { label: 'Unknown', value: SexAtBirth.Unknown }
];

export const MEDICAL_HISTORY_FORM_FIELDS = [
  { fieldID: '1', label: 'High blood pressure' },
  { fieldID: '2', label: 'High blood sugar' },
  { fieldID: '3', label: 'High cholesterol' },
  { fieldID: '4', label: 'Joint or back pains' },
  { fieldID: '5', label: 'Overweight / obese' },
  { fieldID: '6', label: 'Recently suffered an injury' },
  { fieldID: '7', label: 'Depression' },
  { fieldID: '8', label: 'Anxiety' },
  { fieldID: '0', label: 'None of the above' }
];

export const SOCIAL_HISTORY_FORM_FIELDS: FormProps = [
  {
    additional: {
      label: 'How often do you smoke?',
      options: [
        { label: 'I used to smoke', value: '0' },
        { label: 'I currently smoke some days', value: '1' },
        { label: 'I currently smoke every day', value: '2' }
      ]
    },
    id: '1',
    label: 'Cigarettes',
    type: 'select'
  },
  {
    additional: {
      label: 'How often do you vape?',
      options: [
        { label: 'I used to vape', value: '0' },
        { label: 'I currently vape some days', value: '1' },
        { label: 'I currently vape every day', value: '2' }
      ]
    },
    id: '2',
    label: 'Vape',
    type: 'select'
  },
  {
    additional: {
      label: 'How often do you drink?',
      options: [
        { label: 'I used to drink', value: '0' },
        { label: 'I currently drink some days', value: '1' },
        { label: 'I currently drink every day', value: '2' }
      ]
    },
    id: '3',
    label: 'Alcohol',
    type: 'select'
  },
  {
    additional: {
      label: 'What recreational drugs have you used?',
      placeholder: 'e.g. marijuana, cocaine, heroin, amphetamines...'
    },
    id: '4',
    label: 'Recreational drugs',
    type: 'textarea'
  },
  {
    id: '5',
    label: 'I’m sexually active',
    type: 'checkbox'
  },
  {
    id: '0',
    label: 'None of the above',
    type: 'checkbox'
  }
];

export const CHRONIC_DISEASES_FORM_FIELDS = [
  { fieldID: '1', label: 'Bowel disease' },
  { fieldID: '2', label: 'Cancer' },
  { fieldID: '3', label: 'Cardiovascular disease' },
  { fieldID: '4', label: 'Diabetes' },
  { fieldID: '5', label: 'Endocrine disease' },
  { fieldID: '8', label: 'Kidney disease' },
  { fieldID: '6', label: 'Liver Disease' },
  { fieldID: '7', label: 'Lung disease' },
  { fieldID: '11', label: 'Parkinson’s disease' },
  { fieldID: '12', label: 'Seizures' },
  { fieldID: '13', label: 'Severe headache syndrome' },
  { fieldID: '14', label: 'Severe hypotension', description: 'Very low blood pressure' },
  { fieldID: '15', label: 'Severe neuropathy or nerve disorder/damage' },
  { fieldID: '9', label: 'Urinary disease' },
  { fieldID: '0', label: 'None of the above' },
  { fieldID: '10', label: 'Other disease' }
];

export const ADDITIONAL_CONDITIONS = [
  { fieldID: '1', label: 'Dry skin or rash' },
  { fieldID: '2', label: 'Gut health or bloating' },
  { fieldID: '3', label: 'Heavy alcohol intake' },
  { fieldID: '4', label: 'Low energy levels' },
  { fieldID: '5', label: 'Muscle cramps or spasms' },
  { fieldID: '6', label: 'PMS ' },
  { fieldID: '7', label: 'Sleep or falling asleep' },
  { fieldID: '8', label: 'Slow recovery after exercise' },
  { fieldID: '0', label: 'None of the above' }
];
// We don't have to precise here as this is just for reference in transforming the data
export const WOMEN_HEALTH_FORM_FIELDS = [
  { fieldID: '0', label: 'Postmenopausal', labelForDisplay: "I'm post-menopausal" },
  { fieldID: '1', label: 'Pregnant', labelForDisplay: "I'm currently pregnant" },
  { fieldID: '2', label: 'LKP', labelForDisplay: 'Neither of the above' }
];
