import { lazy, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { useVerifyIdentityViaCrossCheckMutation } from 'services/general/general';
import { useAddInsuranceMutation } from 'services/insurance/insurance';

import { selectAppointments, selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import CompleteAddress from 'pages/SignUp/regularFlows/CompleteAddress';
import Allergies from 'pages/SignUp/regularFlows/mifSteps/Allergies';
import ChronicDiseases from 'pages/SignUp/regularFlows/mifSteps/ChronicDiseases';
import HealthConditions from 'pages/SignUp/regularFlows/mifSteps/HealthConditions';
import Medications from 'pages/SignUp/regularFlows/mifSteps/Medications';
import MifSummary from 'pages/SignUp/regularFlows/mifSteps/MifSummary';
import Pharmacy from 'pages/SignUp/regularFlows/mifSteps/Pharmacy';
import SocialHistory from 'pages/SignUp/regularFlows/mifSteps/SocialHistory';
import UploadBodyPhoto from 'pages/SignUp/regularFlows/UploadBodyPhoto';

import Intro from 'widgets/identityVerification/Intro';
import Insurance from 'widgets/Insurance/Insurance';
import PreInsurance from 'widgets/PreInsurance';
import { VerificationBannerKeysProps } from 'widgets/VerificationBanner/verificationBanner.types';

import { MIF_STEPS } from 'constants/onboarding';
import { useAppDispatch, useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import { FlowTypes, PlanCodes, SexAtBirth, SignUpSteps } from 'utils/enums';
import { handleRequestCatch, lazyRetry } from 'utils/helpers';

import { Props } from './content.types';

const PreConfirmationPage = lazy(() =>
  lazyRetry(() => import('pages/SignUp/regularFlows/PreConfirmation'))
);

const YouAreAllSet = lazy(() => lazyRetry(() => import('pages/SignUp/regularFlows/YouAreAllSet')));
const WomensHealth = lazy(() =>
  lazyRetry(() => import('pages/SignUp/regularFlows/mifSteps/WomensHealth'))
);

const VerifyWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="mx-auto w-full max-md:h-full md:max-w-screen-sm">{children}</div>;
};

const Content: React.FC<Props> = ({
  moveToStep,
  step,
  updateStepsList,
  isFreemium,
  verificationBannerKeys,
  isPopupFlow = false,
  showPreConfirmationStep = false,
  loading = false
}) => {
  const dispatch = useAppDispatch();
  const [verifyIdentityViaCrossCheck] = useVerifyIdentityViaCrossCheckMutation();
  const [addInsurance, { isLoading }] = useAddInsuranceMutation();

  const { sexAtBirth, isFirstAppointmentCompleted, dob, activePlanCode } =
    useAppSelector(selectUser);
  const { upcomingAppointments } = useAppSelector(selectAppointments);
  const [isInsuranceStepPresented, toggleInsuranceStep] = useToggle(true);
  const [isIdentityStepsPresented, toggleIdentitySteps] = useToggle(true);
  const [keys, setKeys] = useState<VerificationBannerKeysProps>();

  const VERIFY_IDENTITY_STEPS = isFreemium ? [] : [SignUpSteps.VerifyIdentity];
  let STEPS_LIST = [
    ...(showPreConfirmationStep ? [SignUpSteps.PreConfirmation] : []),
    ...(keys?.includes('address') ? [SignUpSteps.CreateNewUser] : []),
    ...(keys?.includes('insurance') && activePlanCode === PlanCodes.WeightManagementMembership
      ? [SignUpSteps.PreInsurance, SignUpSteps.Insurance]
      : []),
    ...(isFreemium
      ? []
      : keys?.includes('isVerifiedByVouched')
        ? [SignUpSteps.VerifyIdentity]
        : []),
    ...(!keys || keys.includes('medicalIntakeCompleted')
      ? [...MIF_STEPS, SignUpSteps.Pharmacy]
      : []),
    ...(keys?.includes('bodyImage') ? [SignUpSteps.UploadBodyPhoto] : [])
  ];
  if (sexAtBirth !== SexAtBirth.Female) {
    STEPS_LIST = STEPS_LIST.filter((s) => s !== SignUpSteps.WomensHealth);
  }
  if (
    !isFirstAppointmentCompleted &&
    !isFreemium &&
    activePlanCode !== PlanCodes.WeightManagementMembership &&
    !isPopupFlow
  ) {
    const AGE_STEPS = !!dob ? [] : [SignUpSteps.Intro];
    STEPS_LIST.push(...[...AGE_STEPS, SignUpSteps.SCResults]);
  }
  if (!isIdentityStepsPresented) {
    STEPS_LIST = STEPS_LIST.filter(
      (s) => !VERIFY_IDENTITY_STEPS.includes(s) || s === SignUpSteps.VerifyIdentity
    );
  } else if (!STEPS_LIST.some((s) => VERIFY_IDENTITY_STEPS.includes(s))) {
    const index = STEPS_LIST.indexOf(SignUpSteps.VerifyIdentity);
    STEPS_LIST.splice(index + 1, 0, ...VERIFY_IDENTITY_STEPS);
  }

  if (!isInsuranceStepPresented) {
    STEPS_LIST = STEPS_LIST.filter((s) => s !== SignUpSteps.Insurance);
  }

  const handleSkipIdentitySteps = () => {
    toggleIdentitySteps(false);
    if (!keys?.includes('medicalIntakeCompleted') && !keys?.includes('bodyImage')) {
      // 5 is to end form
      moveToStep(5);
    } else {
      moveToStep('next');
    }
  };

  const makeCrossCheck = async () => {
    verifyIdentityViaCrossCheck()
      .unwrap()
      .then(({ data }) => {
        if (data.isVerified) {
          dispatch(setUser({ identityVerified: true }));
          toggleIdentitySteps(false);
        }
      });
  };

  const handleSelectInsurance = async (type: boolean) => {
    if (type === true) {
      toggleInsuranceStep(type);
      moveToStep('next');
    } else {
      await addInsurance({
        hasInsurance: type
      })
        .unwrap()
        .then(() => {
          if (!keys?.includes('medicalIntakeCompleted') && !keys?.includes('isVerifiedByVouched')) {
            moveToStep(5);
          } else {
            toggleInsuranceStep(type);
            moveToStep('next');
          }
        })
        .catch(handleRequestCatch);
    }
  };

  const { isTTPatient } = useWeightManagement();

  useEffect(() => {
    setKeys(verificationBannerKeys);
    toggleIdentitySteps(verificationBannerKeys?.includes('isVerifiedByVouched'));
  }, []);

  const content: { [key in SignUpSteps]?: ReactElement } = {
    [SignUpSteps.PreConfirmation]: (
      <div className="mx-auto max-w-[500px]">
        <PreConfirmationPage
          key={SignUpSteps.PreConfirmation}
          moveToStep={moveToStep}
          selectedFlow={FlowTypes.BasicFlow}
        />
      </div>
    ),
    [SignUpSteps.CreateNewUser]: (
      <div className="mx-auto h-full max-w-screen-sm">
        <CompleteAddress
          callback={isPopupFlow ? makeCrossCheck : undefined}
          key={SignUpSteps.CreateNewUser}
          moveToStep={moveToStep}
          shouldRefetchAccountData={!isPopupFlow}
        />
      </div>
    ),
    [SignUpSteps.PreInsurance]: (
      <div className="mx-auto flex size-full max-w-screen-sm flex-col gap-4 md:gap-6">
        <h1 className="wm-signup-title md:text-center">Insurance coverage</h1>
        <PreInsurance
          className={isPopupFlow ? '' : 'md:bg-white md:shadow'}
          key={SignUpSteps.PreInsurance}
          loading={loading || isLoading}
          ppType={isTTPatient ? 'tt' : 'glp-1'}
          onSelect={handleSelectInsurance}
        />
      </div>
    ),
    [SignUpSteps.Insurance]: (
      <div className="mx-auto h-full max-w-screen-sm">
        <h1 className="mb-4 text-m2xl font-bold text-primary-700 md:mb-6 md:text-center md:text-2xl">
          Insurance information
        </h1>
        <Insurance
          key={SignUpSteps.Insurance}
          loading={loading}
          src="intake-form"
          onContinue={() => moveToStep('next')}
        />
      </div>
    ),
    [SignUpSteps.HealthConditions]: (
      <HealthConditions key="health-conditions" moveToStep={moveToStep} />
    ),
    [SignUpSteps.UploadBodyPhoto]: (
      <UploadBodyPhoto key="upload-body-photo" moveToStep={moveToStep} />
    ),
    [SignUpSteps.ChronicDiseases]: (
      <ChronicDiseases key="chronic-diseases" moveToStep={moveToStep} />
    ),
    [SignUpSteps.SocialHistory]: <SocialHistory key="chronic-diseases" moveToStep={moveToStep} />,
    [SignUpSteps.WomensHealth]: <WomensHealth key="women-health" moveToStep={moveToStep} />,
    [SignUpSteps.Medications]: <Medications key="medications" moveToStep={moveToStep} />,
    [SignUpSteps.Allergies]: <Allergies key="allergies" moveToStep={moveToStep} />,
    [SignUpSteps.Pharmacy]: <Pharmacy key="pharmacy" moveToStep={moveToStep} />,
    [SignUpSteps.MifSummary]: (
      <MifSummary
        key={SignUpSteps.MifSummary}
        moveToStep={moveToStep}
        shouldUpdateStatus={!isPopupFlow}
        steps={STEPS_LIST}
      />
    ),
    [SignUpSteps.SCResults]: (
      <YouAreAllSet apptID={(upcomingAppointments || [])[0]?._id || ''} key="you-are-all-set" />
    ),
    [SignUpSteps.VerifyIdentity]: (
      <VerifyWrapper>
        <Intro
          inPopup={isPopupFlow}
          onContinue={() => {
            toggleIdentitySteps(true);
            moveToStep('next');
          }}
          onSkip={handleSkipIdentitySteps}
        />
      </VerifyWrapper>
    )
  };

  const contentClassName = classNames(
    'flex flex-col flex-grow max-w-[500px] w-full mx-auto max-md:h-full px-0.5',
    { '!max-w-[904px]': STEPS_LIST[step] === SignUpSteps.Pharmacy },
    {
      '!max-w-screen-sm': [
        SignUpSteps.VerifyIdentity,
        SignUpSteps.PreInsurance,
        SignUpSteps.Insurance
      ].includes(STEPS_LIST[step])
    }
  );

  useEffect(() => {
    STEPS_LIST.length && updateStepsList?.(STEPS_LIST);
  }, [STEPS_LIST.length]);

  return (
    <div className={contentClassName}>
      <AnimatePresence mode="wait">{content[STEPS_LIST[step]]}</AnimatePresence>
    </div>
  );
};

export default Content;
