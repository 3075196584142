import { Common } from '@thecvlb/design-system/lib/src';

import { getFixedFinalPrice } from '../paymentCheckout.settings';

import { AcceptedInsuranceProps } from './acceptedInsurance.types';

const AcceptedInsurance: React.FC<AcceptedInsuranceProps> = ({ insuranceCoverageData }) => {
  const isPercentageDiscount =
    typeof insuranceCoverageData?.coinsurance === 'number' &&
    insuranceCoverageData?.coinsurance > 0;
  const fixedFinalPrice = getFixedFinalPrice(insuranceCoverageData?.finalCost);

  return (
    <div className="mb-6 flex justify-between gap-2 rounded-2xl border border-gray-200 bg-gray-100 p-4 md:mb-8">
      <div className="flex flex-col gap-2">
        <p className="text-sm font-bold">Your insurance has been accepted</p>
        <div className="flex gap-1">
          <Common.Icon name="check-shield" />
          <div>
            <p className="text-sm">
              Your total {isPercentageDiscount ? 'coinsurance' : 'copay'} is
              {isPercentageDiscount
                ? ` ${insuranceCoverageData.coinsurance}% `
                : ` $${fixedFinalPrice} `}
              for this telehealth appointment.
            </p>
            <p className="text-sm text-gray">
              {isPercentageDiscount ? 'Coinsurance' : 'Copay'} does not include cost of medication.
            </p>
          </div>
        </div>
      </div>
      <Common.Icon className="size-[30px] text-green" name="check-circle" />
    </div>
  );
};

export default AcceptedInsurance;
