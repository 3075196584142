import classNames from 'classnames';

import { AppointmentCardWithPlanUpdateProps } from './appointmentCardWithPlanUpdate.types';

const AppointmentCardWithPlanUpdate: React.FC<AppointmentCardWithPlanUpdateProps> = ({
  callMethod,
  appointmentCost,
  finalPrice,
  membershipInfo
}) => {
  const isMessageBasedAppointment = callMethod === 'message';
  const discountForPlan = Number(membershipInfo?.totalCost) - Number(membershipInfo?.discount || 0);

  const rowWrapperClassName = 'mb-4 md:mb-3';
  const subheadingClassName = 'block text-mSm md:text-sm';
  const rowClassName = 'mb-1 flex justify-between font-bold md:mb-0.5';
  return (
    <div className="rounded-2xl border border-secondary-200 bg-secondary-50 px-4 py-6 md:p-6">
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName}>
          <span className="block">This appointment</span>
          <span>{`$${appointmentCost}`}</span>
        </div>
        <span className={classNames(subheadingClassName, 'md:w-5/6')}>
          {isMessageBasedAppointment
            ? 'Message-based appointment'
            : 'Video-based visit with a provider'}
        </span>
      </div>
      {membershipInfo && (
        <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
          <div className={rowClassName}>
            <span className="block">{membershipInfo.planName}</span>
            {!!membershipInfo.discount ? (
              <span>
                <span className="line-through opacity-30">{`$${membershipInfo.totalCost}`}</span>
                {` ${discountForPlan === 0 ? 'Free' : `$${discountForPlan}`}`}
              </span>
            ) : (
              <span
                className=""
                data-testid="provider_appointment_price"
              >{`$${membershipInfo.totalCost}`}</span>
            )}
          </div>
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>
            Renews at {`$${membershipInfo.totalCost}`}
            {`${membershipInfo?.paymentIntervalUnits}`}
          </span>
        </div>
      )}
      <div className="my-4 h-px w-full bg-secondary" />
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName} data-testid="total_due">
          <span>Total due now</span>
          <span>{`$${finalPrice}`}</span>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCardWithPlanUpdate;
