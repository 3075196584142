import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { selectNewAppointmentExtended } from 'store';

import { NewPasswordFormInput } from 'pages/MyAccount/components/ChangePassword/NewPasswordForm/newPasswordForm.types';

import NewPasswordInput from 'shared/form/NewPasswordInput';

import { useAppSelector } from 'hooks';

const Password: React.FC<{ loading: boolean; onContinue: (password: string) => void }> = ({
  onContinue,
  loading
}) => {
  const { userFirstName: firstName } = useAppSelector(selectNewAppointmentExtended);
  const { control, handleSubmit, getValues } = useForm<NewPasswordFormInput>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onSubmit = () => {
    const password = getValues('newPassword');
    if (password) {
      onContinue(password);
    }
  };

  return (
    <form className="flex h-full flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="main-header text-primary-700">{`${firstName}, please create a secure password to sign up.`}</h3>
      <NewPasswordInput
        control={control}
        label="Password"
        name="newPassword"
        placeholder="Enter your password..."
        size="lg"
      />
      <Common.Button
        className="max-md:mt-auto md:mx-auto"
        color="blue"
        isLoading={loading}
        fullWidthOnMobile
      >
        Continue
      </Common.Button>
    </form>
  );
};

export default Password;
