import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { nanoid } from 'nanoid';

import { useUploadDocumentsMutation } from 'services/documents/documents';

import DropContainer from 'features/DropContainer';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import { notifyError } from 'shared/Toast/Toast';

import { rotateConfig, transitionConfig } from './documentUploader.settings';
import { DocumentUploaderProps } from './documentUploader.types';

const DocumentUploader: React.FC<DocumentUploaderProps> = ({ getDocuments, onToggle }) => {
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useToggle(false);
  const [dragOverlay, setDragOverlay] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState<'success' | 'error' | null>(null);
  const [filesForUpload, setFilesForUpload] = useState<FileList | null>(null);
  const [uploadDocuments] = useUploadDocumentsMutation();

  const handleUpdatedFilesForUpload = async () => {
    if (!filesForUpload) return;

    setIsLoading(true);
    try {
      await Promise.all(
        [...filesForUpload].map(async (file) => {
          const formData = new FormData();
          formData.append('patientDocumentFile', file);
          formData.append('sourcePage', 'documents-list');
          await uploadDocuments({ body: formData }).unwrap();
          setCounter((v) => v + 1);
        })
      );
      setUploadingStatus('success');
      getDocuments();
      setFilesForUpload(null);
    } catch (e) {
      notifyError((e as Error).message || 'Error, please try again');
      setUploadingStatus('error');
    } finally {
      setTimeout(() => {
        onToggle(false);
        setUploadingStatus(null);
      }, 3000);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleUpdatedFilesForUpload();
  }, [filesForUpload]);

  const orClassName = classNames(
    'hidden text-base font-bold md:block',
    dragOverlay ? 'text-white' : 'text-gray'
  );

  const currentFileCount = Math.min(Number(filesForUpload?.length), counter + 1);

  return (
    <AnimatePresence mode="wait">
      {uploadingStatus ? (
        <FadeWrapper
          className={classNames(
            'my-4 flex flex-col items-center justify-center gap-4 rounded-xl p-8 font-bold md:h-[280px]',
            uploadingStatus === 'success' ? 'bg-green text-white' : 'bg-red-100 text-red'
          )}
        >
          <Common.Icon
            className="size-10"
            name={uploadingStatus === 'success' ? 'check-circle' : 'error'}
          />
          {uploadingStatus === 'success' ? 'Success!' : 'Error, please try again'}
        </FadeWrapper>
      ) : filesForUpload ? (
        <FadeWrapper className="my-4 flex flex-col items-center gap-4 rounded-xl bg-gray-100 p-8">
          {isLoading && (
            <motion.div animate={rotateConfig} transition={transitionConfig}>
              <Common.Logo name="cross" />
            </motion.div>
          )}
          <p className="font-bold">
            Uploading {currentFileCount} of {filesForUpload.length}...
          </p>
          <div className="flex w-full max-w-[385px] flex-col gap-2">
            {Array.from(filesForUpload).map((file: File) => (
              <div
                className="flex items-center gap-2 truncate rounded-2xl bg-gray-200 px-4 py-2 text-base font-medium text-gray-700"
                key={nanoid()}
              >
                <Common.Icon className="size-8 text-primary" name="articles" />
                <span className="flex-1 truncate">{file.name}</span>
              </div>
            ))}
          </div>
        </FadeWrapper>
      ) : (
        <FadeWrapper>
          <DropContainer
            className={classNames(
              'my-4 flex cursor-pointer flex-col items-center gap-4 rounded-xl border border-dashed border-gray-400 p-8 text-center transition',
              { 'bg-primary-400': dragOverlay }
            )}
            setDragOverlay={setDragOverlay}
            onChange={(res) => setFilesForUpload(res)}
          >
            <Common.Icon className="size-10 text-gray md:size-12" name="articles" />
            <h2 className="text-mLg font-bold text-primary md:hidden">Upload a document</h2>
            <div>
              <p className={orClassName}>Drop your photos or videos here to start uploading.</p>
              <p className={dragOverlay ? 'text-white' : 'text-gray'}>
                Your care team will be able to see any documents you upload here.
              </p>
            </div>
            <span className={orClassName}>OR</span>
            <Common.Button color="blue-alt" dataTestId="browse_files">
              Browse files
            </Common.Button>
          </DropContainer>
        </FadeWrapper>
      )}
    </AnimatePresence>
  );
};

export default DocumentUploader;
