import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';

import { SelectMedicationProps } from './selectMedication.types';

const SelectMedication: React.FC<SelectMedicationProps> = ({
  pp,
  onContinue,
  tirzepatidePP,
  ppType
}) => {
  const ITEMS = [
    {
      label: ppType === 'bundle' ? 'Tirzepatide plan' : 'Tirzepatide maintenance',
      value: tirzepatidePP?.planPricePointId || '',
      description: 'No longer available',
      disabled: true,
      subLabel: <span className="text-gray">${tirzepatidePP?.monthlyPrice}/mo</span>
    },
    {
      subLabel: (
        <span className="text-green">
          <span className="mr-1 text-gray line-through">${Number(pp?.monthlyPrice) + 100}</span>$
          {pp?.monthlyPrice}/mo
        </span>
      ),
      label:
        ppType === 'bundle'
          ? 'Semaglutide bundled plan (Medication included)'
          : 'Semaglutide maintenance',
      value: pp?.planPricePointId || ''
    },
    {
      label: 'Discuss with my provider',
      value: 'appointment',
      description: 'You’ll create an appointment at your next renewal.'
    }
  ];
  const [selected, setSelected] = useState(ITEMS[1].label);

  const handleContinue = () => {
    onContinue(selected as 'appointment' | 'price-point');
  };

  return (
    <div className="flex h-full grow flex-col">
      <div className="flex flex-col gap-4 py-6">
        <div className="flex flex-col items-center gap-2 py-6 text-center">
          <h3 className="text-m2xl font-bold text-primary-700 md:text-2xl">
            Please choose how you want to continue
          </h3>
          <span>
            We recommend the bundled compounded semaglutide plan, which includes care and the cost
            of medication.
          </span>
        </div>
        <div className="flex flex-col gap-3">
          <RadioGroup items={ITEMS} selectedItem={selected} onSelect={setSelected} />
        </div>
      </div>
      <Common.Button
        className="py-6 max-md:mt-auto md:mx-auto"
        color="blue"
        fullWidthOnMobile
        onClick={handleContinue}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default SelectMedication;
