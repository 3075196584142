import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { CareTypeCardProps } from './careTypeCard.types';

const CareTypeCard: React.FC<CareTypeCardProps> = ({
  title,
  subtitle,
  icon,
  onClick,
  className = ''
}) => {
  return (
    <button
      className={classNames(
        'flex h-[115px] text-start flex-col justify-between rounded-2xl bg-white p-4',
        className
      )}
      data-testid="care_type_card"
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        {icon}
        <Common.Icon className="text-gray" name="arrow-right" />
      </div>
      <div>
        <h3>{title}</h3>
        {!!subtitle && <p className="text-sm text-gray">{subtitle}</p>}
      </div>
    </button>
  );
};

export default CareTypeCard;
