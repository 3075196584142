import { useTitle } from 'react-use';
import classNames from 'classnames';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useCreateAppointmentExtended } from 'hooks/useCreateAppointmentExtended';

import Content from './Content';

const CreateAppointmentExtended = () => {
  useTitle('LifeMD - Schedule new appointment');

  const {
    currentStep,
    exitFlow,
    isFetching,
    loading,
    moveToStep,
    isBackAnimation,
    currentPlan,
    selectedPlan,
    handleSelectTime,
    handleUpgradePlan,
    appointmentTypes,
    isRequiredToUpgradeToLifeMDPlus,
    handleCreateAccount,
    handleSubmitMIF
  } = useCreateAppointmentExtended();

  return (
    <FadeWrapper
      className={classNames('flex h-full flex-col md:pt-8', {
        'pointer-events-none': isFetching
      })}
    >
      <Loader isVisible={isFetching} />
      <Header hideStepper onClickPrev={() => moveToStep('prev')} />
      <Content
        appointmentTypes={appointmentTypes}
        currentPlan={currentPlan}
        exitFlow={exitFlow}
        isBackAnimation={isBackAnimation}
        isRequiredToUpgradeToLifeMDPlus={isRequiredToUpgradeToLifeMDPlus}
        loading={loading}
        moveToStep={moveToStep}
        selectedPlan={selectedPlan}
        step={currentStep}
        onCreateAccount={handleCreateAccount}
        onSelectDateAndTime={handleSelectTime}
        onSubmitMIF={handleSubmitMIF}
        onUpgradePlan={handleUpgradePlan}
      />
    </FadeWrapper>
  );
};

export default CreateAppointmentExtended;
