import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';

import useWeightManagement from 'hooks/useWeightManagement';

import { PrescriptionForProps, PrescriptionForType } from './prescriptionFor.types';

const ITEMS: { description: string; label: string; value: PrescriptionForType }[] = [
  {
    label: 'A new prescription',
    description: 'Quick prescriptions available for urgent and common conditions.',
    value: 'urgent-issue'
  },
  {
    label: 'A prescription refill',
    description: 'Refill of a prescription previously prescribed by your LifeMD provider.',
    value: 'another-type'
  }
];
const PrescriptionFor: React.FC<PrescriptionForProps> = ({
  onChoosePrescriptionFor,
  appointmentTypes
}) => {
  const { hadOrHasWeightManagement } = useWeightManagement();
  const onClickOption = (value: PrescriptionForType) => {
    const defaultItem =
      value === 'another-type'
        ? appointmentTypes.find(({ displayName }) =>
            displayName.toLowerCase().trim().includes('prescription refill')
          )
        : { _id: '', displayName: '' };

    onChoosePrescriptionFor({
      appointmentTypeId: defaultItem?._id,
      displayName: defaultItem?.displayName,
      prescriptionForType: value
    });
  };

  const filteredOptions = ITEMS.filter(
    (item) => item.value !== 'another-type' || hadOrHasWeightManagement
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" text="Prescriptions" />
        <h1 className="text-m2xl font-bold text-primary-700">
          What do you need a prescription for?
        </h1>
      </div>
      <RadioGroup items={filteredOptions} onSelect={onClickOption} />
    </div>
  );
};

export default PrescriptionFor;
