import { useNavigate } from 'react-router-dom';

import { ActionType } from 'services/wm/wm.types';

import { selectUser } from 'store';

import MigrateFromTirzepatide from 'containers/MigrateFromTirzepatide';

import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

const MigrateFromTirzepatidePage = () => {
  const { weightManagement } = useAppSelector(selectUser);

  const navigate = useNavigate();

  const { orderId } =
    weightManagement?.incompleteSurveys.find((el) => el.type === ActionType.renewal) || {};

  return (
    <MigrateFromTirzepatide
      onComplete={() =>
        navigate(
          orderId
            ? {
                pathname: PathName.PrescriptionStatus,
                search: `orderId=${orderId}&type=renewal`
              }
            : PathName.Dashboard
        )
      }
      onLeaveFlow={() => navigate(-1)}
    />
  );
};

export default MigrateFromTirzepatidePage;
