import { Common } from '@thecvlb/design-system';
import HTMLReactParser from 'html-react-parser';

import Collapse from 'shared/Collapse';
import Loader from 'shared/Loader';

import { useGetLifeMDPlusPlan } from 'hooks/useGetLifeMDPlusPlan';
import { parseText } from 'utils/parseText';

import { SubscriptionRequiredProps } from './subscriptionRequired.types';

const SubscriptionRequired: React.FC<SubscriptionRequiredProps> = ({
  category,
  callMethod,
  onSelect
}) => {
  const { lifeMDPlusPlan, defaultPricePoint, isFetching } = useGetLifeMDPlusPlan();

  const pricePerPeriod = (price: number, period: number) => {
    return period === 1 ? `${price}/mo` : `${price}/every ${period} mo`;
  };

  const handleSelect = () => {
    if (!lifeMDPlusPlan || !defaultPricePoint) throw new Error('No plan found');
    onSelect({
      callMethod,
      planId: lifeMDPlusPlan._id,
      pricePoint: defaultPricePoint
    });
  };

  const planBonuses = (
    defaultPricePoint?.additionalInfo ??
    lifeMDPlusPlan?.additionalInfo ??
    []
  ).map((info) => info.question);

  return (
    <div className="flex flex-col gap-6">
      <Loader isVisible={isFetching} />
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" icon="check" text={category} />
        <h1 className="text-m2xl font-bold text-primary-700">
          Welcome to the future of personalized care
        </h1>
      </div>
      <div className="flex gap-4 rounded-2xl border-2 border-blue bg-blue-50 p-6">
        <div className="flex flex-1 flex-col">
          <p className="font-semibold">{lifeMDPlusPlan?.planName}</p>
          <p className="mt-1 text-mSm text-primary-400">
            <span className="text-primary-200 line-through">
              {pricePerPeriod(
                +(defaultPricePoint?.totalCost ?? 0),
                defaultPricePoint?.paymentInterval.qty ?? 1
              )}
            </span>{' '}
            Free for your first month
          </p>
          <p className="mt-2 text-mSm">Discounted appointments, annual labs and more.</p>
          <ul className="mt-4 flex flex-col gap-2">
            {planBonuses.map((item) => (
              <li className="flex gap-2 text-mSm" key={item}>
                <Common.Icon className="flex-none text-primary-400" name="check" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-semibold text-primary-700">Frequently Asked Questions</h2>
        {defaultPricePoint?.faqDetails?.map((item) => (
          <Collapse key={item.title} title={item.title}>
            {HTMLReactParser(parseText(item.text))}
          </Collapse>
        ))}
      </div>
      <Common.Button className="m-auto" color="blue" fullWidthOnMobile onClick={handleSelect}>
        {callMethod === 'video' ? 'Continue' : 'Continue to payment'}
      </Common.Button>
    </div>
  );
};

export default SubscriptionRequired;
