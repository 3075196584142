import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { useGetAppointmentsQuery } from 'services/appointments/appointments';
import { useGetPrescriptionsQuery } from 'services/patientChart/patientChart';

import { selectAppointments, selectUser } from 'store';
import { AppointmentItem } from 'store/appointments/appointments.types';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import FirstAppointment from 'widgets/completeAccount/Intro/FirstAppointment';

import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useFreemium from 'hooks/useFreemium';
import useGLPPrescription from 'hooks/useGLPPrescription';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { APPOINTMENTS_TABS, COMPLETE_ACCOUNT_STEPS } from 'utils/constants';
import { AppointmentListTypes, DateFormat, PathName } from 'utils/enums';
import { checkWMNotCompletedOnboarding, isAbleToScheduleNewAppointment } from 'utils/helpers';

const Appointments = () => {
  useTitle('LifeMD - Appointments');
  const logEvent = useAnalytics();
  const { isMobile } = useWidth();
  const { upcomingAppointments, pastAppointments } = useAppSelector(selectAppointments);
  const { isFirstAppointmentCompleted, state } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const isFreemium = useFreemium();
  const [tab, setTab] = useState(APPOINTMENTS_TABS[0].label);
  const GLP_PRESCRIPTION = useGLPPrescription();
  const { isAsyncPlan, isWeightManagement } = useWeightManagement();

  const firstAppointment = (upcomingAppointments || [])[0];

  const { isLoading } = useGetAppointmentsQuery({
    appointmentListType: AppointmentListTypes.UPCOMING
  });
  useGetAppointmentsQuery({
    appointmentListType: AppointmentListTypes.PAST
  });

  useGetPrescriptionsQuery(undefined, { skip: !isAsyncPlan });

  const handleClickContactSupport = () => {
    logEvent('appointments_contact_support_btn_click');
    navigate(PathName.Messages);
  };
  const handleClickNewAppointment = () => {
    const isAbleToScheduleAppt = !isFreemium || !!state;

    logEvent('appointments_new_appt_btn_click');
    navigate(
      isAbleToScheduleAppt
        ? PathName.CreateAppointmentExtended
        : `${PathName.CompleteAccount}/${COMPLETE_ACCOUNT_STEPS[1]}`
    );
  };

  const handleClickTab = (label: string) => {
    label === 'Upcoming' && logEvent('appointments_upcoming_tab_click');
    label === 'History' && logEvent('appointments_history_tab_click');
    setTab(label);
  };

  const handleClickRequestAppointment = () => {
    logEvent('appointments_request_appointment_btn_click');
    handleClickNewAppointment();
  };

  const getAppointment = (item: AppointmentItem) => (
    <Common.Appointment
      isQueueAppointment={item.category === 'queue'}
      key={item._id}
      startTime={item.appointmentTime?.startTime}
      text={`${item.appointmentType.displayName}${item.staffName ? ` • ${item.staffName}` : ''}`}
      type={
        item.missedAppointment
          ? 'missed'
          : item.appointmentMethod === 'message'
            ? 'message'
            : item.isStartsSoon && !item.pastAppointment
              ? 'start'
              : 'default'
      }
      onClick={() => {
        logEvent('appointments_appt_item_click');
        navigate(
          item.appointmentMethod === 'message'
            ? `${PathName.Appointment}/${item._id}`
            : item.isStartsSoon && !item.pastAppointment
              ? `${PathName.ZoomCall}?callId=${item._id}`
              : `${PathName.Appointment}/${item._id}`
        );
      }}
    />
  );

  const wmNotCompletedOnboardingAppt = checkWMNotCompletedOnboarding(
    isWeightManagement,
    isFirstAppointmentCompleted
  );

  const showCreateNewApptButton = isAbleToScheduleNewAppointment(
    upcomingAppointments || [],
    isWeightManagement,
    isFirstAppointmentCompleted,
    isAsyncPlan
  );

  return (
    <FadeWrapper className="flex flex-1 flex-col rounded-2xl bg-transparent md:flex-none md:bg-white md:shadow">
      <Loader isVisible={isLoading} />
      {!(isFreemium && isMobile) && (
        <div className="border-gray-200 md:border-b md:px-8 md:pt-4">
          <Common.Tabs
            data={APPOINTMENTS_TABS}
            type={isMobile ? 'bar' : 'line'}
            onChange={(el) => handleClickTab(el.label)}
          />
        </div>
      )}
      <div
        className={classNames(
          'flex flex-1 flex-col gap-3 py-4 md:max-w-[664px] md:flex-none md:gap-4 md:p-8',
          {
            'mx-auto w-full':
              tab === 'Upcoming' &&
              (isFreemium ||
                wmNotCompletedOnboardingAppt ||
                (isAsyncPlan && !isFirstAppointmentCompleted))
          }
        )}
        data-testid="appointments_area"
      >
        {tab === 'Upcoming' && !isLoading && (
          <FadeWrapper
            className={classNames('flex flex-col gap-4 py-4', {
              'md:gap-8': isFreemium || isAsyncPlan
            })}
          >
            {(wmNotCompletedOnboardingAppt || (isAsyncPlan && !isFirstAppointmentCompleted)) && (
              <>
                <Common.Illustration className="mx-auto" name="calendar" />
                <h2 className="text-center text-mXl font-bold text-primary-700 md:text-2xl">
                  {isAsyncPlan ? 'We’ve got you covered!' : 'Welcome to Weight Management!'}
                </h2>
                {firstAppointment && !isFirstAppointmentCompleted && (
                  <p className="text-center text-primary-700">
                    Thanks for being a Weight Management member! You have your first appointment
                    {firstAppointment.appointmentMethod !== 'message' && (
                      <>
                        {' '}
                        on{' '}
                        <b>
                          {dayjs(firstAppointment.appointmentTime?.startTime).format(
                            DateFormat.MMM_D_h_mma_z
                          )}
                        </b>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
            {isAsyncPlan && !(upcomingAppointments || []).length ? (
              <>
                <NavLink
                  className="flex items-center justify-between gap-5 rounded-2xl border border-gray-200 bg-white p-4 shadow"
                  to={PathName.WeightManagement}
                >
                  <div>
                    <h3 className="font-bold">GLP-1 prescription</h3>
                    {GLP_PRESCRIPTION && (
                      <p className="mt-0.5 text-sm text-gray">
                        Medication requested: ${GLP_PRESCRIPTION.medication.name}
                      </p>
                    )}
                  </div>
                  <Common.Button className="flex-none max-md:hidden" color="green-alt">
                    View status
                  </Common.Button>
                  <button className="text-gray md:hidden">
                    <Common.Icon name="arrow-right" />
                  </button>
                </NavLink>
                <div className="mx-auto flex gap-4 max-md:mt-8">
                  <Common.Button color="white-alt" size="md" onClick={handleClickContactSupport}>
                    Contact support
                  </Common.Button>
                  {isFirstAppointmentCompleted && (
                    <Common.Button size="md" onClick={handleClickRequestAppointment}>
                      Request appointment
                    </Common.Button>
                  )}
                </div>
              </>
            ) : (
              <>
                {isFreemium && <FirstAppointment className="md:mt-4" />}
                {showCreateNewApptButton && (
                  <button
                    className="hidden w-full items-center gap-5 rounded-2xl border border-dashed px-5 py-4 text-base font-semibold text-gray-700 transition-all hover:bg-secondary-50 md:flex"
                    onClick={handleClickNewAppointment}
                  >
                    <Common.Icon
                      className="size-11 rounded-lg bg-secondary-100 p-3.5 text-secondary"
                      name="plus"
                    />
                    Create a new appointment
                  </button>
                )}
                {(upcomingAppointments || []).map(getAppointment)}
                {!(upcomingAppointments || []).length && !isFreemium && (
                  <span className="text-gray" data-testid="no_upcoming_appo_msg">
                    No upcoming appointments
                  </span>
                )}
              </>
            )}
          </FadeWrapper>
        )}
        {tab === 'History' && (
          <FadeWrapper className="flex flex-col gap-3 md:gap-4">
            {(pastAppointments || []).map(getAppointment)}
            {!(pastAppointments || []).length && (
              <span className="text-gray" data-testid="no_past_appo_msg">
                No past appointments
              </span>
            )}
          </FadeWrapper>
        )}
        {showCreateNewApptButton && (
          <Common.Button
            className="mx-auto mt-auto md:hidden"
            color="blue"
            preIcon="plus"
            style="pill"
            onClick={handleClickNewAppointment}
          >
            New appointment
          </Common.Button>
        )}
      </div>
    </FadeWrapper>
  );
};

export default Appointments;
