import { useEffect } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetSet } from 'react-use';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

import {
  useAddAppointmentMutation,
  useUploadFilesMutation
} from 'services/appointments/appointments';
import {
  useAddPersonalInfoMutation,
  useCombinedPaymentMutation,
  useHoldProviderMutation,
  useRefreshTokenMutation
} from 'services/auth/auth';
import { useGenerateDynamicLinkQueueMutation } from 'services/general/general';
import { DynamicLinkActions } from 'services/general/general.types';
import { useGetAppointmentTypesQuery, useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useSendMifResponseMutation } from 'services/mifs/mifs';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import {
  RootState,
  selectMifInfo,
  selectNewAppointmentExtended,
  selectOfferAsyncAppointments,
  selectUser,
  store
} from 'store';
import {
  clearNewAppointmentExtended,
  setNewAppointmentExtended
} from 'store/appointments/appointmentsSlice';
import { clearAppointmentMif } from 'store/mif/mifSlice';
import { clearUser, setUser } from 'store/user/userSlice';

import { Props } from 'containers/CreateAppointmentExtended/Content/content.types';
import {
  buildBodyForApptSchedule,
  defineFlowSteps
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.settings';
import {
  CareTypes,
  CREATE_APPOINTMENT_STEPS,
  PaymentStepProps,
  StepName
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';
import { notifyError, notifySuccess } from 'shared/Toast/Toast';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { WM_INSURANCE_VALID_PRICE_POINTS } from 'constants/pricepoints';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { useGetLifeMDPlusPlan } from 'hooks/useGetLifeMDPlusPlan';
import { PathName, PlanCodes } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';
import { handlePostHogEvent } from 'utils/posthog';

import usePartnerPatient from '../usePartnerPatient';
import useWidth from '../useWidth';

import { AppointmentCallMethod } from 'models/appointment.types';

import {
  buildBodyForPayment,
  buildBodyForUserCreation,
  buildPayload,
  getNextStep
} from './useCreateAppointmentExtended.settings';

export const useCreateAppointmentExtended = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const { lifeMDPlusPlan, defaultPricePoint } = useGetLifeMDPlusPlan();

  const isAsyncEnabledForTheUser = useAppSelector(selectOfferAsyncAppointments);
  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const { activePlanCode, activePlanId, activePricePoint, accessToken, refreshToken } =
    useAppSelector(selectUser);
  const {
    membershipData,
    src,
    plans: appointmentPlans,
    mifCode,
    asyncAllowed,
    uploadRequired,
    ...rest
  } = useAppSelector(selectNewAppointmentExtended);

  const isOptaviaPatient = usePartnerPatient('Optavia');
  const isHRTPatient = usePartnerPatient('HRT');
  const isKrogerPatient = usePartnerPatient('Kroger');

  const partner = isHRTPatient
    ? 'HRT'
    : isOptaviaPatient
      ? 'Optavia'
      : isKrogerPatient
        ? 'Kroger'
        : null;

  const [handleRefreshToken] = useRefreshTokenMutation();
  const {
    data: appointmentTypes,
    isLoading: isLoadingApptTypes,
    isFetching: isFetchingApptTypes
  } = useGetAppointmentTypesQuery({
    ...(!!partner && { partner }),
    accessToken: ''
  });
  const [updateMyAccount, { isLoading: isLoadingUpdateUser }] = useUpdateMyAccountMutation();
  const [addAppointment, { isLoading: isLoadingAddAppointment }] = useAddAppointmentMutation();
  const { data: plans, isFetching: isFetchingPlans } = useGetMembershipPlansQuery();
  const [submitMif, { isLoading: isSubmittingResults }] = useSendMifResponseMutation();
  const [submitPayment, { isLoading: isLoadingSubmitPayment }] = useCombinedPaymentMutation();
  const [getMyAccount, { isFetching }] = useLazyGetMyAccountQuery();
  const [createAccount, { isLoading: isLoadingCreateAccount }] = useAddPersonalInfoMutation();
  const [holdProvider, { isLoading }] = useHoldProviderMutation();
  const [generateDynamicLink, { isLoading: isGeneratingLink }] =
    useGenerateDynamicLinkQueueMutation();

  const [uploadFiles] = useUploadFilesMutation();

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);
  const [steps, setSteps] = useGetSet([...CREATE_APPOINTMENT_STEPS]);

  const continueInWeb = () => {
    sessionStorage.setItem('appointmentIsScheduled', 'true');
    dispatch(clearAppointmentMif());
    moveToStep('confirmation');
  };

  const continueInApp = () => {
    const reactiveState = store.getState() as RootState;
    const apptID = reactiveState.appointments.newAppointmentExtended._id;
    if (!apptID) {
      return notifyError('Appointment is not scheduled');
    }
    generateDynamicLink({
      token: reactiveState.user.expiredToken ?? '',
      accessToken,
      action: DynamicLinkActions.APPOINTMENT_CONFIRMATION,
      appointmentId: apptID
    })
      .unwrap()
      .then(({ data: { dynamicLink } }) => setTimeout(() => window.open(dynamicLink, '_top')))
      .catch((e) => handleRequestCatch(e, 'Please try again'));
  };

  const predefinedCategory = query.get('c');
  const currentStep = (query.get('s') ?? '') as StepName;
  const srcFromQuery = (useQuery().get('src') as CareTypes) ?? '';
  const dateFromQuery = query.get('date') || '';

  const currentPlan = plans?.data.find((p) => p.planCode === activePlanCode);
  const selectedPlan = plans?.data.find(
    (p) => p._id === (membershipData?.planId ? membershipData.planId : activePlanId)
  );

  const selectedPP = selectedPlan?.pricePoints.find(
    (pp) => pp.planPricePointId === activePricePoint
  );

  const isRequiredToUpgradeToLifeMDPlus =
    !activePlanCode ||
    activePlanCode === PlanCodes.FlexCare ||
    (!!appointmentPlans ? !appointmentPlans.some((p) => p.code === activePlanCode) : false);
  const isInsurancePatient =
    !!activePricePoint && WM_INSURANCE_VALID_PRICE_POINTS.includes(activePricePoint);
  const haveToPayForAppt =
    isRequiredToUpgradeToLifeMDPlus ||
    Number(selectedPP?.subsequentAppointmentCost) > 0 ||
    isInsurancePatient;

  const exitFlow = (to: To, options?: NavigateOptions) => {
    navigate(to, options);
    dispatch(clearNewAppointmentExtended());
  };

  const scheduleAppointment = (
    callMethod?: AppointmentCallMethod | null,
    onScheduleAppointment?: () => void
  ) => {
    const body = buildBodyForApptSchedule({
      ...rest,
      callType: callMethod || rest.callMethod
    });
    const scheduleAppointmentThen = () => {
      sessionStorage.setItem('appointmentIsScheduled', 'true');
      dispatch(clearAppointmentMif());
      moveToStep('confirmation');
    };

    addAppointment(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(setNewAppointmentExtended({ _id: data._id, status: 'created' }));
        if (rest.files?.length && uploadRequired) {
          const formData = new FormData();
          rest.files.forEach((file: File) => {
            formData.append('appointmentImages', file);
          });
          uploadFiles({
            appointmentId: data._id,
            body: formData
          })
            .unwrap()
            .catch(() => {
              toast.warn(
                'Appointment is scheduled successfully but there is an error with the uploading of the files'
              );
            })
            .finally(scheduleAppointmentThen);
        } else {
          scheduleAppointmentThen();
        }
      })
      .catch((e) => {
        handleRequestCatch(e);
        moveToStep('date-time');
      })
      .finally(() => onScheduleAppointment?.());
  };

  const handleSelectTime = (data: { doctorId: string; endTime: string; startTime: string }) => {
    dispatch(setNewAppointmentExtended(data));
  };

  const handleSubmitMIF = (shouldSendResults?: boolean) => {
    if (!shouldSendResults) {
      return moveToStep({ step: 'mif' });
    }
    const body = appointmentMif.map(({ question, answer, textAreaFields }) => ({
      question,
      answer,
      ...(textAreaFields && { textAreaFields })
    }));
    mifCode &&
      submitMif({ id: mifCode, body })
        .unwrap()
        .then(() => {
          moveToStep({ step: 'mif' });
        })
        .catch(handleRequestCatch);
  };

  // This method is for the guest flow only - we create account, then submit mif (if needed) and then move next
  const handleCreateAccount = (password: string) => {
    const body = buildBodyForUserCreation({ ...rest, password });
    createAccount(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(
          setUser({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            userId: data._id
          })
        );
        getMyAccount()
          .unwrap()
          .then(() => {
            if (!!mifCode) {
              handleSubmitMIF(true);
            } else {
              moveToStep({ step: 'create-account-password' });
            }
          })
          .catch(handleRequestCatch);
      })
      .catch((e) => {
        if (e?.data?.message === 'A user with the same phone number is already registered') {
          moveToStep('create-account-phone', 'phone_exists=true');
        } else {
          moveToStep('create-account-personal-details', 'email_exists=true');
        }
      });
  };

  const handleNewUser = (formData: PaymentFormFields) => {
    if (!defaultPricePoint || !lifeMDPlusPlan) {
      throw new Error('LifeMD plus membership plan is not found');
    }

    const handleCreateUserThen = () => {
      const isMobileWebView = sessionStorage.getItem('mobile-webview') === 'true';
      if (isMobile && isMobileWebView) {
        continueInApp();
      } else {
        continueInWeb();
      }
    };
    const body = buildBodyForPayment({
      data: { ...rest, accessToken },
      formData,
      defaultPricePoint,
      lifeMDPlusPlan
    });

    submitPayment(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(setUser({ ...data.userData, elationId: String(data.userData.elationId) }));
        dispatch(setNewAppointmentExtended({ _id: data.appointmentData._id, status: 'created' }));
        if (rest.files?.length && uploadRequired) {
          const formData = new FormData();
          rest.files.forEach((file: File) => {
            formData.append('appointmentImages', file);
          });
          uploadFiles({
            appointmentId: data.appointmentData._id,
            body: formData
          })
            .unwrap()
            .catch(() => {
              toast.warn(
                'Appointment is scheduled successfully but there is an error with the uploading of the files'
              );
            })
            .finally(handleCreateUserThen);
        } else {
          handleCreateUserThen();
        }
      })
      .catch((e) => {
        if (e?.status === 401 && !!refreshToken) {
          handleRefreshToken({ isAuthorized: true, token: refreshToken })
            .unwrap()
            .then(() => handleNewUser(formData))
            .catch((e) => {
              handleRequestCatch(e, 'Session expired, please login and try again');
              dispatch(clearNewAppointmentExtended());
              dispatch(clearUser());
              navigate({ pathname: PathName.Login }, { replace: true });
            });
        } else {
          handleRequestCatch(
            e,
            !!refreshToken
              ? 'Can not connect your card'
              : 'Session expired, please login and try again'
          );
          handlePostHogEvent('purchase_failed');
          if (!refreshToken) {
            dispatch(clearUser());
            dispatch(clearNewAppointmentExtended());
            navigate({ pathname: PathName.Login }, { replace: true });
          }
        }
      });
  };

  const handleUpgradePlan = (data: PaymentStepProps) => {
    if (!isRequiredToUpgradeToLifeMDPlus && !data.isNewUser) {
      return scheduleAppointment();
    }
    if (data.isNewUser) {
      handleNewUser(data.formData);
    } else {
      updateMyAccount({
        planId: membershipData?.planId,
        ...(!!membershipData?.planPricePoint && {
          planPricePointId: membershipData?.planPricePoint?.planPricePointId
        })
      })
        .unwrap()
        .then(() => {
          scheduleAppointment(null, () => {
            dispatch(
              setUser({
                activePricePoint: membershipData?.planPricePoint?.planPricePointId,
                activePlanId: selectedPlan?._id
              })
            );
          });
        });
    }
  };

  const moveToStep: Props['moveToStep'] = (type, extraSearch = ''): void | Promise<void> => {
    const isQualifiedForAsyncAppointment = (store.getState() as RootState).appointments
      ?.newAppointmentExtended?.isQualifiedForAsyncAppointment;
    const additionalSearch = extraSearch ? '&' + extraSearch : '';
    const currentStepIndex = steps().indexOf(currentStep);
    // if we need to store some data in redux, let's do it here
    const payload = buildPayload(type);
    !!payload && dispatch(setNewAppointmentExtended(payload));
    const basicExitPath = !!accessToken ? PathName.Dashboard : PathName.Login;

    // when arg type is an object, then we'll have conditional next step, so below is the logic to define it
    if (typeof type === 'object') {
      const nextStep = getNextStep(type, {
        src: srcFromQuery || src,
        accessToken,
        isRequiredToUpgradeToLifeMDPlus,
        shouldAllowToSeeAsyncSelectScreen:
          !isInsurancePatient &&
          isAsyncEnabledForTheUser &&
          !!asyncAllowed &&
          isQualifiedForAsyncAppointment !== false, // don't change. If value is null, it means that user didn't fill mif at all (suitable).
        // And if it's true, then user doesn't have any DQ during mif
        haveToPayForAppt,
        uploadRequired
      });

      if (nextStep === 'shop') {
        return navigate({ pathname: PathName.Shop });
      }
      if (nextStep === 'mif') {
        dispatch(clearAppointmentMif());
      }
      if (
        type.step === 'qualified-for-async' &&
        type.data.callMethod === 'message' &&
        !haveToPayForAppt
      ) {
        return scheduleAppointment(type.data.callMethod);
      }
      if (type.step === 'date-time' && !haveToPayForAppt) {
        return scheduleAppointment();
      }
      if (type.step === 'payment-checkout') {
        return;
      }

      if (type.step === 'date-time' && !activePlanCode) {
        const body = {
          appointmentTime: {
            startTime: rest.startTime,
            endTime: rest.endTime
          },
          appointmentTypeId: rest.appointmentTypeId,
          doctorId: rest.doctorId,
          planId: membershipData?.planId,
          sessionId: nanoid(),
          timezone: dayjs.tz.guess(),
          state: rest.userState ?? ''
        };
        return holdProvider(body)
          .unwrap()
          .then(({ data }) => {
            dispatch(setNewAppointmentExtended({ bookedSlotId: data.bookedSlotId }));
            const indexOfCurrentStep = steps().indexOf(type.step);
            const indexOfTheNextStep = !!nextStep ? steps().indexOf(nextStep) : 0;
            toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
            return nextStep
              ? navigate({ search: `s=${nextStep}${additionalSearch}` })
              : exitFlow({ pathname: basicExitPath });
          });
      }

      const indexOfCurrentStep = steps().indexOf(type.step);
      const indexOfTheNextStep = !!nextStep ? steps().indexOf(nextStep) : 0;
      toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
      return nextStep
        ? navigate({ search: `s=${nextStep}${additionalSearch}` })
        : exitFlow({ pathname: basicExitPath });
    } else if (type === 'prev') {
      toggleBackAnimation(true);
      return navigate(-1);
    } else if (type === 'next') {
      toggleBackAnimation(false);
      let nextStep = steps()[currentStepIndex + 1];
      return nextStep
        ? navigate({ search: `s=${nextStep}${additionalSearch}` })
        : exitFlow({ pathname: basicExitPath });
    } else {
      if (!steps().includes(type)) {
        toggleBackAnimation(true);
        notifyError('Something went wrong, please try again');
        return navigate({ search: `s=${steps()[0]}` }, { replace: true });
      }
      toggleBackAnimation(false);
      navigate(
        { search: `s=${type}${additionalSearch}` },
        {
          replace: type === 'confirmation'
        }
      );
    }
  };

  const isQualifiedForAsyncAppointment = (store.getState() as RootState).appointments
    ?.newAppointmentExtended?.isQualifiedForAsyncAppointment;

  const onInit = () => {
    if (srcFromQuery) {
      const correctSRCOptions: CareTypes[] = ['prescriptions', 'talk-to-a-doctor', 'shop-labs'];
      setNewAppointmentExtended({
        src: correctSRCOptions.includes(srcFromQuery) ? srcFromQuery : 'talk-to-a-doctor'
      });
    }
    if (!accessToken) {
      setSteps([...CREATE_APPOINTMENT_STEPS]);
      if (!currentStep || !CREATE_APPOINTMENT_STEPS.includes(currentStep)) {
        navigate({ search: `s=${CREATE_APPOINTMENT_STEPS[0]}` }, { replace: true });
        dispatch(clearNewAppointmentExtended());
      }
      return;
    }
    getMyAccount()
      .unwrap()
      .then(() => {
        if (
          (currentStep !== 'confirmation' && rest.status === 'created') ||
          currentStep === 'choose-type-of-care'
        ) {
          dispatch(clearNewAppointmentExtended());
        }
        if (predefinedCategory) {
          dispatch(setNewAppointmentExtended({ displayName: predefinedCategory }));
        }
        if (dateFromQuery) {
          dispatch(setNewAppointmentExtended({ initialDate: dateFromQuery }));
        }
        const filteredSteps: StepName[] = defineFlowSteps(CREATE_APPOINTMENT_STEPS, {
          isRequiredToUpgradeToLifeMDPlus,
          isInsurancePatient,
          isExistingPatient: !!accessToken,
          haveToPayForAppt
        });
        setSteps(filteredSteps);
        if (!currentStep || !filteredSteps.includes(currentStep)) {
          navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
          dispatch(clearNewAppointmentExtended());
        }
      })
      .catch(handleRequestCatch);
  };

  useEffect(onInit, []);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
    if (rest.status === 'created' && currentStep !== 'confirmation') {
      dispatch(clearNewAppointmentExtended());
      navigate(PathName.Appointments, { replace: true });
    }
    if (!!accessToken && currentStep.includes('create-account')) {
      notifySuccess('Your account is created successfully');
      moveToStep({
        step: 'create-account-password'
      });
    }
    if (isQualifiedForAsyncAppointment === false && currentStep === 'qualified-for-async') {
      moveToStep({ step: 'qualified-for-async', data: { callMethod: 'video' } });
    }
    if (!currentStep) {
      navigate({ search: 's=choose-type-of-care' }, { replace: true });
    }
  }, [currentStep]);

  return {
    selectedPlan,
    currentStep,
    isFetching: isFetching || isFetchingPlans || isFetchingApptTypes, // fetching is only for initial loading (to hide rest of the screen or make it's disabled before data is loaded)
    //loading for the loading spinner
    loading:
      isLoadingUpdateUser ||
      isLoadingAddAppointment ||
      isFetching ||
      isLoadingApptTypes ||
      isLoadingCreateAccount ||
      isSubmittingResults ||
      isLoadingSubmitPayment ||
      isLoading ||
      isGeneratingLink,
    moveToStep,
    steps: steps(),
    exitFlow,
    isBackAnimation: isBackAnimation(),
    currentPlan,
    handleSelectTime,
    handleUpgradePlan,
    handleCreateAccount,
    handleSubmitMIF,
    appointmentTypes: appointmentTypes?.data ?? [],
    isRequiredToUpgradeToLifeMDPlus
  };
};
