import { AppointmentTypeItem } from 'models/appointment.types';

import { AppointmentOption } from './appointmentTypesPicker.types';

export const sortApptOptions = (optionA: AppointmentOption, optionB: AppointmentOption) => {
  const orderA = optionA.data.featuredOrder;
  const orderB = optionB.data.featuredOrder;

  if (orderA === undefined && orderB === undefined) return 0;
  if (orderA === undefined) return 1;
  if (orderB === undefined) return -1;

  return orderA - orderB;
};

export const setAppointmentOptions = (
  onlyFeatured: boolean,
  appointmenttypes?: AppointmentTypeItem[],
  searchKey?: string
) => {
  return (
    appointmenttypes
      ?.filter((type) => !onlyFeatured || type.featured)
      ?.filter(
        (type) => !searchKey || type.displayName.toLowerCase().includes(searchKey.toLowerCase())
      )
      ?.map((type) => ({
        label: type.displayName,
        description: type?.subtitle,
        value: type._id,
        data: type
      }))
      .sort(sortApptOptions) ?? []
  );
};
