import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import {
  selectDocumentsAndLinksStatus,
  selectHealthProfileStatus,
  selectLabsAndImagingStatus,
  selectMedicalRecordsStatus,
  selectMessagingStatus,
  selectMigrateToBundlePlanStatus,
  selectMyProviderStatus,
  selectReOnboardingWMStatus,
  selectUser
} from 'store';

import Account from 'pages/Account';
import AccountSettings from 'pages/AccountSettings';
import Appointment from 'pages/Appointment';
import AppointmentMif from 'pages/AppointmentMif';
import Appointments from 'pages/Appointments';
import ChangePlan from 'pages/ChangePlan';
import Checkout from 'pages/Checkout';
import KrogerConnectID from 'pages/KrogerConnectID';
import KrogerLocations from 'pages/KrogerLocations';
// import CreateAppointment from 'pages/CreateAppointment';
import Messages from 'pages/Messages';
import MigrateFromTirzepatide from 'pages/MigrateFromTirzepatide';
import MyAccount from 'pages/MyAccount';
import About from 'pages/MyAccount/components/About';
import AccountInfo from 'pages/MyAccount/components/AccountInfo';
import BillingDetails from 'pages/MyAccount/components/BillingDetails';
import ChangePassword from 'pages/MyAccount/components/ChangePassword';
import HippaAgreement from 'pages/MyAccount/components/HippaAgreement';
import Insurance from 'pages/MyAccount/components/Insurance';
import Legal from 'pages/MyAccount/components/Legal';
import MedicaidWaiver from 'pages/MyAccount/components/MedicaidWaiver';
import Notifications from 'pages/MyAccount/components/Notifications';
import MyChart from 'pages/MyChart';
import NotFound from 'pages/NotFound';
import PaymentMethod from 'pages/PaymentMethod';
import Cart from 'pages/shop/Cart';
import ShopCheckout from 'pages/shop/Checkout';
import Home from 'pages/shop/Home';
import Order from 'pages/shop/Order';
import History from 'pages/shop/OrdersHistory';
import Product from 'pages/shop/Product';
import Address from 'pages/shop/ShippingAddress';
import Shop from 'pages/shop/Shop';
import UpgradePlan from 'pages/UpgradePlan';
import UpgradeToAnnualPlan from 'pages/UpgradeToAnnualPlan';

import CreateAppointmentExtended from 'containers/CreateAppointmentExtended';
import MigrateToTT from 'containers/MigrateToTT';
import MigrateToWM from 'containers/MigrateToWM';
import PostOnboarding from 'containers/PostOnboarding';
import Loader from 'shared/Loader';
import MyChartDashboard from 'widgets/myChart/Dashboard';
import Documents from 'widgets/myChart/Documents';
import HealthProfile from 'widgets/myChart/HealthProfile';
import Labs from 'widgets/myChart/Labs';
import LabResults from 'widgets/myChart/Labs/LabResults';
import MedicalRecords from 'widgets/myChart/MedicalRecords';
import Prescriptions from 'widgets/myChart/Prescriptions';
import MyProvider from 'widgets/myChart/Provider';

import { SIGNUP_PATH } from 'constants/onboarding';
import { FrontDeskProvider } from 'contexts/frontDeskContext/frontDeskContext';
import { ZoomCallProvider } from 'contexts/zoomCallContext/zoomCallContext';
import { useAppSelector } from 'hooks';
import useFreemium from 'hooks/useFreemium';
import usePartnerPatient from 'hooks/usePartnerPatient';
import useWeightManagement from 'hooks/useWeightManagement';
import socketFrontDesk from 'socket/socketFrontDesk';
import { FeatureFlag, InternalLinkAction, PathName } from 'utils/enums';
import { lazyRetry } from 'utils/helpers';

import Router, { SignupRouter } from './Router';
import { RouteScrollTop } from './ScrollToTop';

const Login = lazy(() => lazyRetry(() => import('pages/Login')));
const SignUp = lazy(() => lazyRetry(() => import('containers/SignUp')));
const UnsupportedAge = lazy(() => lazyRetry(() => import('pages/UnsupportedAge')));
const Dashboard = lazy(() => lazyRetry(() => import('pages/Dashboard')));
const Main = lazy(() => lazyRetry(() => import('layouts/Main')));
const PrescriptionStatus = lazy(() => lazyRetry(() => import('pages/PrescriptionStatus')));
const DeviceStatus = lazy(() => lazyRetry(() => import('pages/DeviceStatus')));
const AddMeasurement = lazy(() => lazyRetry(() => import('pages/AddMeasurement')));
const CompleteAccount = lazy(() => lazyRetry(() => import('containers/CompleteAccount')));
const CreateNewPassword = lazy(() => lazyRetry(() => import('pages/CreateNewPassword')));
const ForgotPassword = lazy(() => lazyRetry(() => import('pages/ForgotPassword')));
// const CreateFreeAccount = lazy(() => lazyRetry(() => import('pages/CreateFreeAccount')));
const WeightManagement = lazy(() => lazyRetry(() => import('pages/WeightManagement')));
const WeightManagementTabs = lazy(() => lazyRetry(() => import('pages/WeightManagementTabs')));
const AppointmentRequired = lazy(() => lazyRetry(() => import('pages/AppointmentRequired')));
const Article = lazy(() => lazyRetry(() => import('pages/Article')));
const ZoomCall = lazy(() => lazyRetry(() => import('pages/ZoomCall')));
const VerifyEmail = lazy(() => lazyRetry(() => import('pages/VerifyEmail')));
const ConfirmAccount = lazy(() => lazyRetry(() => import('pages/ConfirmAccount')));
const OptaviaSupport = lazy(() => lazyRetry(() => import('pages/Support')));
const MigrateToPlan = lazy(() => lazyRetry(() => import('pages/MigrateToPlan')));
const MigrateToBundlePlan = lazy(() => lazyRetry(() => import('pages/MigrateToBundlePlan')));
const MigrateToBundlePlanFromWM = lazy(() =>
  lazyRetry(() => import('pages/MigrateToBundlePlanFromWM'))
);
const UnpaidInvoice = lazy(() => lazyRetry(() => import('pages/UnpaidInvoice')));
const KrogerSavingsClub = lazy(() => lazyRetry(() => import('pages/KrogerSavingsClub')));
const CurrentWeight = lazy(() => lazyRetry(() => import('pages/CurrentWeight')));
const KrogerPlus = lazy(() => lazyRetry(() => import('pages/KrogerPlus')));
const VerifyYourIdentity = lazy(() => lazyRetry(() => import('pages/VerifyYourIdentity')));

const AppRouter = () => {
  const { flagsReady } = useFlagsStatus();
  const isEnabledReportsFeature = useFlag(FeatureFlag.Reports);
  const isEnabledMedicalRecordsFeature = useFlag(FeatureFlag.MedicalRecords);
  const isEnabledHealthProfilePageFeature = useFlag(FeatureFlag.HealthProfilePage);
  const isEnabledDocumentsPageFeature = useFlag(FeatureFlag.DocumentsPage);
  const isEnableMigrationToBundlePlan = useFlag(FeatureFlag.MigrateToBundlePlan);

  const { weightManagement, accessToken, doctorId, state } = useAppSelector(selectUser);
  const isDisabledMyProvider = useAppSelector(selectMyProviderStatus);
  const isDisabledDocumentsAndLinks = useAppSelector(selectDocumentsAndLinksStatus);
  const isDisabledHealthProfile = useAppSelector(selectHealthProfileStatus);
  const isDisabledLabsAndImaging = useAppSelector(selectLabsAndImagingStatus);
  const isDisabledMedicalRecords = useAppSelector(selectMedicalRecordsStatus);
  const isDisabledMigrateToBundlePlan = useAppSelector(selectMigrateToBundlePlanStatus);
  const isExtendedReOnboardingWMFlowEnabled = useAppSelector(selectReOnboardingWMStatus);

  const isPaywallMessaging = useAppSelector((store) => selectMessagingStatus(store, 'paywall'));
  const { search } = useLocation();
  const isFreemium = useFreemium();
  const { isWeightManagement, hadOrHasWeightManagement, isTTPatient, isInsurancePatient } =
    useWeightManagement();

  const hasUpdateInfo = search.includes('newPlanID') || search.includes('newPPID');

  const isOptaviaPatient = usePartnerPatient('Optavia');
  const isKrogerPatient = usePartnerPatient('Kroger');

  const isAbleToScheduleAppt = !isFreemium || !!state;

  const isAbleToChangeToTheBundle =
    isEnableMigrationToBundlePlan &&
    (!isDisabledMigrateToBundlePlan ||
      isExtendedReOnboardingWMFlowEnabled ||
      weightManagement?.offerBundled?.isRequiredAppointment);
  const isAbleToChangeToMoveFromTirzepatide = true;

  if (!flagsReady) {
    return <Loader isVisible />;
  }
  return (
    <>
      <RouteScrollTop />
      <Suspense fallback={<Loader isVisible />}>
        <Routes>
          <Route
            element={
              isAbleToScheduleAppt ? (
                <CreateAppointmentExtended />
              ) : (
                <Navigate to={PathName.CompleteAccount + '/quick-check'} />
              )
            }
            path={PathName.CreateAppointmentExtended}
          />
          <Route
            element={accessToken ? <Navigate to={`${PathName.Dashboard + search}`} /> : <SignUp />}
            path={`${PathName.SignUp}/:plan/:flow/:stepName`}
          />
          <Route element={<SignupRouter />} path={`${PathName.SignUp}/:plan/:flow`} />
          <Route element={<SignupRouter />} path={`${PathName.SignUp}/:plan`} />
          <Route element={<SignupRouter />} path={`${PathName.SignUp}`} />
          <Route element={<UnsupportedAge />} path={PathName.UnsupportedAge} />
          <Route element={<Router />}>
            <Route
              element={
                <ZoomCallProvider>
                  <FrontDeskProvider socket={socketFrontDesk}>
                    <Routes>
                      <Route element={<Main />}>
                        <Route element={<Dashboard />} index />
                        <Route element={<ZoomCall />} path={PathName.ZoomCall} />
                        <Route element={<OptaviaSupport />} path={PathName.Support} />
                        <Route
                          element={
                            isPaywallMessaging ? (
                              <Navigate to={PathName.Dashboard} replace />
                            ) : (
                              <Messages />
                            )
                          }
                          path={PathName.Messages}
                        />
                        <Route element={<Messages />} path={PathName.CreateNewRequest} />
                        <Route element={<Appointments />} path={PathName.Appointments} />
                        <Route
                          element={
                            isKrogerPatient ? (
                              <KrogerSavingsClub />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.KrogerSavingsClub}
                        />
                        <Route
                          element={
                            isKrogerPatient ? (
                              <KrogerLocations />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.KrogerLocations}
                        />
                        <Route
                          element={
                            isKrogerPatient ? (
                              <KrogerConnectID />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.KrogerConnectID}
                        />
                        <Route
                          element={<Navigate to={PathName.CreateAppointmentExtended} replace />}
                          path={PathName.CreateAppointment}
                        />
                        <Route
                          element={<UpgradeToAnnualPlan />}
                          path={PathName.UpgradeToAnnualPlan}
                        />
                        <Route element={<Appointment />} path={`${PathName.Appointment}/:id`} />
                        <Route element={<MyChart />}>
                          <Route element={<MyChartDashboard />} path={PathName.MyChart} />
                          <Route
                            element={
                              !isDisabledMedicalRecords && isEnabledMedicalRecordsFeature ? (
                                <MedicalRecords />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.MedicalRecords}
                          />
                          <Route
                            element={
                              !isDisabledLabsAndImaging && isEnabledReportsFeature ? (
                                <Labs />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.Labs}
                          />
                          <Route
                            element={
                              !isDisabledLabsAndImaging && isEnabledReportsFeature ? (
                                <LabResults />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={`${PathName.Labs}/:id`}
                          />
                          <Route element={<Prescriptions />} path={PathName.Prescriptions} />
                          <Route
                            element={
                              !isDisabledDocumentsAndLinks && isEnabledDocumentsPageFeature ? (
                                <Documents />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.Documents}
                          />
                          <Route
                            element={
                              doctorId && !isDisabledMyProvider ? (
                                <MyProvider />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.MyProvider}
                          />
                          <Route
                            element={
                              !isDisabledHealthProfile && isEnabledHealthProfilePageFeature ? (
                                <HealthProfile />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.HealthProfile}
                          />
                        </Route>
                        <Route element={<Account />} path={PathName.Account} />

                        <Route element={<AccountSettings />} path={PathName.AccountSettings} />
                        <Route element={<MyAccount />}>
                          <Route element={<AccountInfo />} path={PathName.AccountInformation} />
                          <Route element={<ChangePassword />} path={PathName.ChangePassword} />
                          <Route element={<Insurance />} path={PathName.Insurance} />
                          <Route
                            element={<Notifications />}
                            path={PathName.NotificationsSettings}
                          />
                          <Route
                            element={
                              isFreemium ? (
                                <Navigate to={PathName.NotFound} replace />
                              ) : (
                                <BillingDetails />
                              )
                            }
                            path={PathName.BillingDetails}
                          />
                          <Route element={<About />} path={PathName.About} />
                          <Route element={<Legal />} path={PathName.Legal} />
                          <Route element={<MedicaidWaiver />} path={PathName.MedicaidWaiver} />
                          <Route element={<HippaAgreement />} path={PathName.HippaAgreement} />
                        </Route>
                        <Route
                          element={<PaymentMethod />}
                          path={PathName.PaymentMethods + '/:action'}
                        />
                        <Route element={<UpgradePlan />} path={PathName.UpgradePlan} />
                        <Route element={<ChangePlan />} path={PathName.ChangePlan} />
                        <Route
                          element={
                            isAbleToChangeToTheBundle ? (
                              !isWeightManagement ? (
                                <MigrateToBundlePlan />
                              ) : (
                                <MigrateToBundlePlanFromWM />
                              )
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.MigrateToBundlePlan}
                        />
                        <Route
                          element={
                            !hasUpdateInfo ? (
                              <Navigate to={PathName.ChangePlan} replace />
                            ) : (
                              <Checkout />
                            )
                          }
                          path={PathName.Checkout}
                        />
                        <Route
                          element={
                            isWeightManagement ? (
                              <WeightManagement />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.WeightManagement}
                        />
                        <Route
                          element={
                            isWeightManagement ? (
                              <WeightManagementTabs />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={`${PathName.WeightManagementTabs}/:id`}
                        />
                        <Route
                          element={
                            isWeightManagement ? (
                              <Article />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={`${PathName.WMArticle}/:id`}
                        />
                        <Route
                          element={
                            isKrogerPatient ? (
                              <KrogerPlus />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.KrogerPlus}
                        />
                        <Route element={<UnpaidInvoice />} path={PathName.UnpaidInvoice} />
                        <Route
                          element={
                            isWeightManagement ? (
                              <Shop />
                            ) : (
                              <Navigate to={PathName.NotFound} replace />
                            )
                          }
                          path={PathName.Shop}
                        >
                          <Route element={<Home />} index />
                          <Route element={<Cart />} path={PathName.ShopCart} />
                          <Route element={<ShopCheckout />} path={PathName.ShopCheckout} />
                          <Route
                            element={<Navigate to={PathName.Shop} />}
                            path={PathName.ShopProducts}
                          />
                          <Route element={<Address />} path={PathName.ShopAddress} />
                          <Route element={<Product />} path={'products/:id'} />
                          <Route element={<Navigate to={PathName.Shop} />} path="*" />
                          <Route element={<History />} path={PathName.ShopOrders} />
                          <Route element={<Order />} path={PathName.ShopOrders + '/:id'} />
                        </Route>
                        <Route element={<NotFound />} path={PathName.NotFound} />
                        <Route element={<Navigate to={PathName.NotFound} />} path="*" />
                      </Route>
                      <Route
                        element={
                          isWeightManagement ? (
                            <AppointmentRequired />
                          ) : (
                            <Navigate to={PathName.NotFound} replace />
                          )
                        }
                        path={PathName.AppointmentRequired}
                      />
                      <Route element={<CurrentWeight />} path={PathName.CurrentWeight} />
                      <Route
                        element={
                          hadOrHasWeightManagement && !isWeightManagement ? (
                            <Navigate
                              to={{
                                pathname: PathName.Dashboard,
                                search: `action=${InternalLinkAction.AskToUpgradeToTheWM}`
                              }}
                              replace
                            />
                          ) : (
                            <PrescriptionStatus />
                          )
                        }
                        path={PathName.PrescriptionStatus}
                      />
                      <Route
                        element={
                          isOptaviaPatient ? (
                            <Navigate to={PathName.NotFound} replace />
                          ) : (
                            <AppointmentMif />
                          )
                        }
                        path={PathName.AppointmentMif + '/:mifID'}
                      />
                      <Route
                        element={
                          isWeightManagement ? (
                            <DeviceStatus />
                          ) : (
                            <Navigate to={PathName.NotFound} replace />
                          )
                        }
                        path={PathName.DeviceStatus}
                      />
                      <Route element={<MigrateToPlan />} path={PathName.MigrateToPlan} />
                      <Route
                        element={
                          isWeightManagement ? (
                            <AddMeasurement />
                          ) : (
                            <Navigate to={PathName.NotFound} replace />
                          )
                        }
                        path={PathName.AddMeasurement}
                      />
                      <Route
                        element={
                          isFreemium ? (
                            <CompleteAccount />
                          ) : (
                            <Navigate to={PathName.NotFound} replace />
                          )
                        }
                        path={`${PathName.CompleteAccount}/:stepName?`}
                      />
                      <Route
                        element={
                          isFreemium ? (
                            <CompleteAccount flow="short" />
                          ) : (
                            <Navigate to={PathName.NotFound} replace />
                          )
                        }
                        path={`${PathName.UpgradeAccount}/:stepName?`}
                      />
                      <Route element={<PostOnboarding />} path={PathName.IntakeForm} />
                      <Route
                        element={
                          isAbleToChangeToMoveFromTirzepatide ? (
                            <MigrateFromTirzepatide />
                          ) : (
                            <Navigate to={PathName.MyAccount} />
                          )
                        }
                        path={PathName.MoveFromTirzepatide}
                      />
                      <Route
                        element={
                          isTTPatient || isInsurancePatient ? (
                            <Navigate to={PathName.WeightManagement} />
                          ) : (
                            <MigrateToTT />
                          )
                        }
                        path={PathName.MigrateToTT}
                      />
                      <Route
                        element={
                          !isWeightManagement && !hadOrHasWeightManagement ? (
                            <MigrateToWM />
                          ) : (
                            <Navigate to={PathName.NotFound} replace />
                          )
                        }
                        path={PathName.MigrateToWM}
                      />
                    </Routes>
                  </FrontDeskProvider>
                </ZoomCallProvider>
              }
              path="/*"
            />
            <Route element={<VerifyYourIdentity />} path={PathName.VerifyYourIdentity} />
            <Route element={<Login />} path={PathName.Login} />
            <Route element={<CreateNewPassword />} path={PathName.CreateNewPassword} />
            <Route element={<ForgotPassword />} path={PathName.ForgotPassword} />
            <Route
              element={<Navigate to={SIGNUP_PATH} replace />}
              path={PathName.CreateFreeAccount}
            />
            {/*<Route element={<CreateFreeAccount />} path={PathName.CreateFreeAccount} />*/}
            <Route element={<VerifyEmail />} path={PathName.VerifyEmail} />
            <Route element={<ConfirmAccount />} path={PathName.ConfirmAccount} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRouter;
