import { MifStructure, QuestionWithAnswerConfig } from 'services/mifs/mifs.types';

export const MIGRATE_FROM_TIRZEPATIDE_STEPS = [
  'intro',
  'mif-1',
  'mif-2',
  'results',
  'select-medication',
  'appointment',
  'checkout',
  'all-set'
] as const;

export type PossibleStepsType = typeof MIGRATE_FROM_TIRZEPATIDE_STEPS;

export type StepName = PossibleStepsType[number];

export type PatchedQuestion = Omit<MifStructure['configs'][number], 'config'> & {
  config: QuestionWithAnswerConfig;
};

export type FillMifDataProps =
  | {
      data: {
        answer: string[];
        question: string;
      };
      step: 'mif-1';
    }
  | {
      data: {
        answer: string[];
        question: string;
      };
      step: 'mif-2';
    }
  | {
      data: boolean;
      step: 'results';
    }
  | {
      step: 'select-medication';
    }
  | {
      step: 'appointment';
    }
  | {
      step: 'checkout';
    };
