import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { getIdealRangeText, getLabResultStatusForTag } from './labsResultMetricCard.settings';
import { LabsResultMetricCardProps } from './labsResultMetricCard.types';
import RangeScale from './RangeScale';

const LabsResultMetricCard: React.FC<LabsResultMetricCardProps> = ({
  testName,
  testDescription,
  value,
  units,
  status,
  referenceMin,
  referenceMax,
  description
}) => {
  const [isExpanded, setIsExpanded] = useToggle(false);

  const referenceMinNumber = !!Number(referenceMin?.replace(/\D/g, ''))
    ? Number(referenceMin?.replace(/\D/g, ''))
    : null;
  const referenceMaxNumber = !!Number(referenceMax?.replace(/\D/g, ''))
    ? Number(referenceMax?.replace(/\D/g, ''))
    : null;
  const hasRange = referenceMinNumber || referenceMaxNumber;
  const numberOfValue = value?.length && (!!Number(value) || value === '0') ? Number(value) : null;
  const statusData =
    (hasRange || status) &&
    value &&
    getLabResultStatusForTag(value, status, referenceMin, referenceMax);

  // Note: If description is just one word, such as CBC, hide description in expanded view.
  const showTestDescription = testDescription && testDescription.trim().includes(' ');
  const showExpandedAbility = hasRange || showTestDescription || !!description;

  const idealRangeText = getIdealRangeText(units ?? '', referenceMinNumber, referenceMaxNumber);

  const plusClasses = classNames('size-4 text-gray-300 transition', { 'rotate-45': isExpanded });

  const handleExtendMetricIfo = () => {
    setIsExpanded();
  };

  const titleClasses = classNames('text-gray-700', {
    'col-span-2': !referenceMinNumber && !referenceMaxNumber
  });

  return (
    <div className="relative grid h-fit grid-cols-2 items-center gap-4 rounded-2xl bg-white p-6 md:border md:border-gray-200">
      {showExpandedAbility && (
        <button className="absolute right-4 top-4" onClick={handleExtendMetricIfo}>
          <Common.Icon className={plusClasses} name="plus" />
        </button>
      )}
      <div className={titleClasses}>
        <p className="text-mBase font-semibold">{testName}</p>
        <p className="mb-1 text-mLg font-bold first-letter:uppercase">
          {value} <span className="text-mSm font-semibold">{units}</span>
        </p>
        {statusData && <Common.ColorTag {...statusData} />}
      </div>
      {hasRange && numberOfValue?.toString() && (
        <RangeScale
          referenceMax={referenceMaxNumber}
          referenceMin={referenceMinNumber}
          value={numberOfValue}
        />
      )}
      {isExpanded && (
        <div className="col-span-2 text-mSm font-medium text-gray-500">
          {hasRange && <p>{idealRangeText}</p>}
          {showTestDescription && <p className="mt-4">{testDescription}</p>}
          {!!description && <p className="mt-4">{description}</p>}
        </div>
      )}
    </div>
  );
};

export default LabsResultMetricCard;
