import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useGetPrescriptionsQuery } from 'services/patientChart/patientChart';
import { useCreateRenewalMutation } from 'services/wm/wm';

import { selectPatientChart } from 'store';

import EarlyForRenewal from 'modals/EarlyForRenewal';
import PrescriptionsNotFound from 'modals/PrescriptionsNotFound';
import RenewalPending from 'modals/RenewalPending';
import RadioGroup from 'shared/RadioGroup';

import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import { PathName, TaskStatuses } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { RenewPrescriptionProps } from './renewPrescription.types';
const OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No, I need a different prescription.',
    value: false
  }
];

const RenewPrescription: React.FC<RenewPrescriptionProps> = ({ onSelect }) => {
  const navigate = useNavigate();
  const { isWeightManagement } = useWeightManagement();
  const [isOpenPrescriptionsNotFound, togglePrescriptionsNotFound] = useToggle(false);
  const [isOpenPendingRenewal, togglePendingRenewal] = useToggle(false);
  const [isOpenEarlyForRenewal, toggleEarlyForRenewal] = useToggle(false);

  const { prescriptions } = useAppSelector(selectPatientChart);

  const [createRenewal, { isLoading }] = useCreateRenewalMutation();
  useGetPrescriptionsQuery(undefined, { skip: !isWeightManagement });

  const handleNavigateToPrescription = (orderId: string, type: string) => {
    navigate({
      pathname: PathName.PrescriptionStatus,
      search: `orderId=${orderId}&type=${type}&src=appointment`
    });
  };

  const handleCreateRenewal = (orderId: string) => {
    createRenewal({ orderId })
      .unwrap()
      .then(({ data: { orderId, type } }) => handleNavigateToPrescription(orderId, type))
      .catch(handleRequestCatch);
  };

  const handleOnSelect = (value: boolean) => {
    if (!value) return onSelect();

    const latestPrescription = prescriptions
      .slice()
      .sort((a, b) => dayjs(b.createdDate).diff(a.createdDate))[0];

    if (!latestPrescription) return togglePrescriptionsNotFound();

    const {
      isRenewalCreated,
      renewalTask,
      renewalSurvey,
      isRenewalRequestAllowed,
      renewalRequestAllowedAt,
      orderId
    } = latestPrescription;

    if (
      isRenewalCreated &&
      renewalTask?.status !== TaskStatuses.COMPLETED &&
      !renewalSurvey?.canceledAt &&
      renewalSurvey?.completedAt
    ) {
      return togglePendingRenewal();
    }

    if (
      (isRenewalRequestAllowed &&
        renewalRequestAllowedAt &&
        dayjs().isBefore(renewalRequestAllowedAt)) ||
      !orderId
    ) {
      return toggleEarlyForRenewal();
    }

    if (!isRenewalCreated && isRenewalRequestAllowed) {
      return handleCreateRenewal(orderId);
    }

    handleNavigateToPrescription(orderId, 'renewal');
  };

  return (
    <div className="flex flex-col gap-6">
      <PrescriptionsNotFound
        isOpen={isOpenPrescriptionsNotFound}
        onClose={togglePrescriptionsNotFound}
      />
      <RenewalPending isOpen={isOpenPendingRenewal} onClose={togglePendingRenewal} />
      <EarlyForRenewal isOpen={isOpenEarlyForRenewal} onClose={toggleEarlyForRenewal} />
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" text="Weight management" />
        <h1 className="text-m2xl font-bold text-primary-700">
          Are you looking to renew your prescription?
        </h1>
      </div>
      <RadioGroup isLoading={isLoading} items={OPTIONS} onSelect={handleOnSelect} />
    </div>
  );
};

export default RenewPrescription;
