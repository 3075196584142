import { Common } from '@thecvlb/design-system';

import { selectMifInfo, selectSignUp, selectUser } from 'store';
import { MifProps } from 'store/mif/mif.types';
import { setUser } from 'store/user/userSlice';

import StepHeaderInfo from 'pages/SignUp/regularFlows/mifSteps/parts/StepHeaderInfo';

import { findMifStepIndexByName } from 'containers/SignUp/signup.settings';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName, SexAtBirth } from 'utils/enums';

import MifInfoItem from './MifInfoItem';
import { getStepNameByShortName, mapMifDataToSortedItems } from './mifSummary.settings';
import { Props } from './mifSummary.types';

const MifSummary: React.FC<Props> = ({
  moveToStep,
  selectedFlow,
  steps,
  shouldUpdateStatus = true
}) => {
  const dispatch = useAppDispatch();
  const isSignUp = location.pathname.includes(PathName.SignUp);

  const { mifInfo: mifData } = useAppSelector(selectMifInfo);
  const { sexAtBirth: loggedUserSexAtBirth } = useAppSelector(selectUser);

  const {
    user: { sexAtBirth }
  } = useAppSelector(selectSignUp);
  let sortedData = mapMifDataToSortedItems(mifData);
  if ((isSignUp ? sexAtBirth : loggedUserSexAtBirth) !== SexAtBirth.Female) {
    sortedData = sortedData.filter((e) => e[1] !== 'womenHealth');
  }
  const handleEdit = (value: keyof MifProps) => {
    const stepName = getStepNameByShortName(value);
    const index = isSignUp
      ? findMifStepIndexByName(stepName, sexAtBirth || loggedUserSexAtBirth, selectedFlow)
      : steps.indexOf(stepName);
    moveToStep(index, { searchParams: 'step-action=edit' });
  };

  const handleNext = () => {
    shouldUpdateStatus && dispatch(setUser({ medicalIntakeCompleted: true }));
    moveToStep('next');
  };

  return (
    <>
      <StepHeaderInfo
        dataTestId="confirm_med_info"
        stepSubtitle="Please review your responses."
        stepTitle="Confirm your medical information."
      />
      <div className="flex flex-col gap-4 rounded-2xl border bg-white p-4 md:gap-6 md:border-none md:p-6 md:shadow">
        {sortedData.map((item, i) => (
          <MifInfoItem item={item} key={`${item[1]}-${i}`} onEdit={() => handleEdit(item[1])} />
        ))}
      </div>
      <Common.Button
        className="mx-auto mt-6"
        color="blue"
        dataTestId="continue_btn"
        postIcon="arrow-right"
        fullWidthOnMobile
        onClick={handleNext}
      >
        Continue
      </Common.Button>
    </>
  );
};

export default MifSummary;
