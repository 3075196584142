import { MifProps, RoughPeriodDate, WomenHealthProps } from 'store/mif/mif.types';

import { PERIOD_OPTIONS } from 'widgets/mif/WomensHealth/WomensHealth';

import {
  CHRONIC_DISEASES_FORM_FIELDS,
  MEDICAL_HISTORY_FORM_FIELDS,
  SOCIAL_HISTORY_FORM_FIELDS,
  WOMEN_HEALTH_FORM_FIELDS
} from 'constants/formFields';
import { HealthProfileNavItem, SignUpSteps } from 'utils/enums';

import { MifItem, ValueOf } from './mifSummary.types';

const NAV_ITEMS: { [key in keyof MifProps]?: { label: HealthProfileNavItem; order: number } } = {
  allergyList: { label: HealthProfileNavItem.Allergies, order: 2 },
  chronicDiseases: { label: HealthProfileNavItem.ChronicDiseases, order: 4 },
  healthConditions: { label: HealthProfileNavItem.HealthConditions, order: 3 },
  medications: { label: HealthProfileNavItem.Medications, order: 1 },
  socialHistory: { label: HealthProfileNavItem.SocialHistory, order: 6 },
  womenHealth: { label: HealthProfileNavItem.WomenHealth, order: 5 }
};

const NAV_LABEL_INDEX = 0;

export const mapMifDataToSortedItems = (data: MifProps): MifItem[] => {
  return (Object.entries(data) as unknown as [keyof MifProps, ValueOf<MifProps>][])
    .sort(
      (a, b) =>
        Number(NAV_ITEMS[a[NAV_LABEL_INDEX]]?.order) - Number(NAV_ITEMS[b[NAV_LABEL_INDEX]]?.order)
    )
    .map(([label, values]) => [NAV_ITEMS[label]?.label, label, values] as MifItem)
    .filter((mifItem) => !!mifItem[NAV_LABEL_INDEX]);
};

export const getMifContent = (key: MifItem[1], data: MifItem[2]): string | string[] => {
  switch (key) {
    case 'healthConditions':
      return (data as MifProps['healthConditions']).map(
        (e) => MEDICAL_HISTORY_FORM_FIELDS.find((item) => item.fieldID === e.name)?.label || ''
      );
    case 'chronicDiseases':
      return (data as MifProps['chronicDiseases']).map(
        (e) => CHRONIC_DISEASES_FORM_FIELDS.find((item) => item.fieldID === e.name)?.label || ''
      );
    case 'womenHealth':
      const selectedValue = WOMEN_HEALTH_FORM_FIELDS.find(
        (item) => item.fieldID === (data as MifProps['womenHealth']).name
      );
      if (selectedValue?.label !== 'LKP') {
        return selectedValue?.labelForDisplay || '';
      }
      const getApproxPeriodText = (v: RoughPeriodDate) => {
        const item = PERIOD_OPTIONS.find((option) => option.value === v);
        return item?.label ? `${item.label} ago` : 'unsure';
      };
      const startOrApproxDate =
        (data as WomenHealthProps).startDate ||
        getApproxPeriodText((data as WomenHealthProps).approxStartDate);
      return `Last period date: ${startOrApproxDate}`;
    case 'socialHistory':
      return (data as MifProps['socialHistory']).map((e) => {
        const matched = SOCIAL_HISTORY_FORM_FIELDS.find((item) => item.id === e.name);
        if (!matched) {
          return '';
        }
        switch (matched.type) {
          case 'checkbox':
            return matched.label;
          case 'textarea':
            return e.notes || matched.label;
          case 'select':
            return matched.additional?.options?.[e.useFrequency || '0']?.label || matched.label;
          default:
            return '';
        }
      });
    case 'medications':
      const summary: string[] = [];
      (data as MifProps['medications'])?.prescription?.forEach((e) => summary.push(e.name));
      (data as MifProps['medications'])?.supplements?.forEach((e) => summary.push(e.name));
      return summary;
    case 'allergyList':
      return (data as MifProps['allergyList'])?.map((e) => e.name);
    default:
      return '';
  }
};

export const getStepNameByShortName = (v: keyof MifProps) => {
  const stepNames: { [key in keyof MifProps]: SignUpSteps } = {
    additionalHealthConditions: SignUpSteps.AdditionalConditions,
    allergyList: SignUpSteps.Allergies,
    chronicDiseases: SignUpSteps.ChronicDiseases,
    healthConditions: SignUpSteps.HealthConditions,
    medications: SignUpSteps.Medications,
    preferredPharmacy: SignUpSteps.Pharmacy,
    socialHistory: SignUpSteps.SocialHistory,
    womenHealth: SignUpSteps.WomensHealth
  };
  return stepNames[v];
};
